var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sign-up-header",staticStyle:{"background-image":"url('images/bg-signup.jpg')"}},[_c('div',{staticClass:"content "},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":"/images/logo-white-border.png","width":"150"}}),_c('div',{staticClass:"mt-10 font-size-18"},[_vm._v(_vm._s(_vm.appName))])])]),_c('a-card',{staticClass:"card-signup header-solid",attrs:{"bordered":false,"bodyStyle":{paddingTop: 0}}},[_c('div',{staticClass:"py-40 text-center"},[_c('h1',{staticClass:"mb-5"},[_vm._v("Sign In")]),_c('p',{staticClass:"text-lg"},[_vm._v("Please use the username and account provided by hmc to sign in")])]),_c('a-form',{staticClass:"login-form mx-25",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-15"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'username',
              { rules: [{ required: true, message: 'Please input your Username!' }] } ]),expression:"[\n              'username',\n              { rules: [{ required: true, message: 'Please input your Username!' }] },\n              ]"}],attrs:{"size":"large","placeholder":"Username"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"mb-5"},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{ rules: [{ required: true, message: 'Please input your Password!' }] } ]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your Password!' }] },\n\t\t\t\t\t\t]"}],attrs:{"visibilityToggle":"","size":"large","type":"password","placeholder":"Password"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'agree',
						{
							valuePropName: 'checked',
							initialValue: true,
						} ]),expression:"[\n\t\t\t\t\t\t'agree',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvaluePropName: 'checked',\n\t\t\t\t\t\t\tinitialValue: true,\n\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}]},[_vm._v(" I agree the "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v("Terms and Conditions")])])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","loading":_vm.submitBtnLoading,"size":"large","block":"","html-type":"submit"}},[_vm._v(" SIGN IN ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }