<!--
	This is the new user page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div class="mx-auto mt-50" style="max-width: 1024px;">

    <a-loading :state="pageLoadingState">
      <div class="mb-50 overflow-x-auto py-15 mx-auto" style="max-width: 900px;">

        <a-steps progress-dot v-model="step">
          <a-step :disabled="true" title="Trial Info"/>
          <a-step :disabled="true" title="Devices"/>
          <a-step :disabled="true" title="Site"/>
          <a-step :disabled="true" title="Laboratory"/>
          <a-step :disabled="true" title="Patients"/>
        </a-steps>

      </div>

      <div class="mb-50">
        <!-- Step 1 -->
        <a-card v-show="step === 0" :bordered="false" class="header-solid">
          <h5 class="mt-5">BASIC INFORMATION</h5>
          <a-form
              :form="step0_form"
              @submit="nextStep"
          >
            <a-row :gutter="[24]" type="flex">
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial #" :colon="false">
                  <a-input
                      placeholder="eg. 01"
                      v-decorator="[
                      'projectNo',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input Trial #!'
                          },
                        ],
                      },
                    ]"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial Name" :colon="false">
                  <a-input
                      placeholder="eg. Michael"
                      v-decorator="[
                      'projectName',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input Name!'
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial introduce" :colon="false">
                  <a-textarea
                      placeholder="Autosize height with"
                      :auto-size="{ minRows: 3, maxRows: 6 }"
                      v-decorator="[
                      'projectIntroduce',
                    ]"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="mt-5">CRO Staff</h5>
            <a-row :gutter="[24]" type="flex">
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="CRO Staffs" :colon="false">
                  <a-select
                      mode="multiple"
                      optionFilterProp="title"
                      style="width: 100%"
                      placeholder="Please select"
                      v-decorator="[
                      'userIds',
                    ]"
                  >
                    <a-select-option v-for="item in croEpAndQcs" :key="item.id"
                                     :title="item.username + ' (#'+item.userNo+') - ['+item.roleNameAndIdsStr+']'">
                      {{ item.username }} (#{{ item.userNo }}) - [{{ item.roleNameAndIdsStr }}]
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="mt-5">Files</h5>
            <a-row :gutter="[24]" type="flex">
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial Files" :colon="false">
                  <div class="h-5"></div>
                  <a-upload-dragger
                      accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                      name="file"
                      :multiple="true"
                      :withCredentials="true"
                      action="/api/ctcProject/upload/file"
                      @change="(info)=>handleUploadChange(info, projectFileList)"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox"/>
                    </p>
                    <p class="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p class="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p>
                  </a-upload-dragger>
                </a-form-item>
              </a-col>

              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial Files" :colon="false">
                  <div class="h-5"></div>
                  <a-upload-dragger
                      accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                      name="file"
                      :multiple="true"
                      :withCredentials="true"
                      action="/api/ctcProject/upload/file"
                      @change="(info)=>handleUploadChange(info, projectComplianceFileList)"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox"/>
                    </p>
                    <p class="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p class="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p>
                  </a-upload-dragger>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="24" class="text-right">
                <a-button type="primary" html-type="submit" class="px-25">NEXT</a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <!-- Step 2 -->
        <a-card v-show="step === 1" :bordered="false" class="header-solid">
          <a-form
              :form="step1_form"
              @submit="nextStep"
          >
            <div v-for="(item, index) in productCustomIndices" :key="'product_div_'+item">
              <div class="d-flex mb-10 flex-align-items-center flex-justify-content-space-between">
                <h5 class="my-0 mr-5" style="min-height: 40px">Device {{ index + 1 }}</h5>
                <a-button v-if="productCustomIndices.length>1" @click="removeProduct(index)" type="link">
                  <a-icon type="delete" theme="twoTone" class="m-0 text-xl " two-tone-color="#eb2f96"/>
                </a-button>
              </div>
              <a-row :gutter="[24]" type="flex">
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Device #" :colon="false">
                    <a-input
                        placeholder="eg. Street 111"
                        v-decorator="[
                          'products['+item+'].productNo',
                          {
                            rules: [
                              {
                                required: true, message: 'Please input Device #!'
                              },
                              {
                                validator: (rule, value, callback) =>{
                                  validateProductNoUnique(item, value, callback, step1_form, 'products');
                                },
                              },
                            ],
                          },
                        ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Device Name" :colon="false">
                    <a-input
                        placeholder="eg. Street 111"
                        v-decorator="[
                          'products['+item+'].productName',
                          {
                            rules: [
                              {
                                required: true, message: 'Please input Device Name!'
                              },
                            ],
                          },
                        ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Manufacturer" :colon="false">
                    <a-textarea
                        placeholder="Manufacturer info"
                        :auto-size="{ minRows: 3, maxRows: 6 }"
                        v-decorator="[
                      'products['+item+'].productManufacturer',
                    ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Introduce" :colon="false">
                    <a-textarea
                        placeholder="Introduce"
                        :auto-size="{ minRows: 3, maxRows: 6 }"
                        v-decorator="[
                      'products['+item+'].productIntroduce',
                    ]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <hr class="c-hr"/>
            </div>

            <a-row type="flex" :gutter="[24]" class="flex-justify-content-center"
                   :class="productCustomIndices.length<=0 ? 'my-50' : ''">
              <a-col :span="24" :sm="12">
                <a-form-item
                    class="mb-10 text-center"
                    :colon="false">
                  <a-button type="dashed" @click="addNewProduct" class="px-20">
                    <a-icon type="plus"/>
                    Add New Device
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>


            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="12">
                <a-button @click="prevStep" class="px-25">PREV</a-button>
              </a-col>
              <a-col :span="12" class="text-right">
                <a-button type="primary" html-type="submit" class="px-25">NEXT</a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <!-- Step 3 -->
        <a-card v-show="step === 2" :bordered="false" class="header-solid kanban-board">
          <!--          <a-button slot="extra" @click="showForm = true" class="px-30">-->
          <!--            <a-icon type="plus" class="m-0"/>-->
          <!--          </a-button>-->
          <a-form
              :form="step2_form"
              @submit="nextStep"
          >
            <div>
              <h5 class="mb-10">Site Entry Fields</h5>
              <draggable :list="clinicalPlaceFieldIndices" :animation="200" ghost-class="ghost-card"
                         handle=".kanban-card__move_btn"
                         group="clinicalPlaceFieldIndices">
                <a-card
                    v-for="(item, index) in clinicalPlaceFieldIndices"
                    :key="`clinicalPlaceField_${item}`"
                    :bordered="false"
                    class="kanban-card mb-24 position-relative"
                >

                  <a-button v-if="clinicalPlaceFieldIndices.length>1"
                            @click="removeClinicalPlaceField(index)"
                            class="position-absolute kanban-card__remove_btn" type="danger" shape="circle">
                    <a-icon type="delete" class="m-0 text-md"/>
                  </a-button>
                  <a-button
                      class="position-absolute kanban-card__move_btn" type="" shape="circle"
                      style="transform: rotate(90deg)">
                    <a-icon type="swap" class="m-0 text-md"/>
                  </a-button>
                  <a-row :gutter="[24]" type="flex">
                    <a-col :span="24" :md="7" :sm="12">
                      <a-form-item class="mb-10" label="Title" :colon="false">
                        <a-input
                            placeholder="eg. Street 111"
                            v-decorator="[
                            'clinicalPlaceFields['+item+'].title',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please input title!'
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="7" :sm="12">
                      <a-form-item class="mb-10" label="Key (a-z, A-Z, _, $)" :colon="false">
                        <a-input
                            placeholder="eg. Street 111"
                            v-decorator="[
                            'clinicalPlaceFields['+item+'].key',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please input key'
                                },
                                {
                                  pattern: /^([a-zA-Z_$])([a-zA-Z0-9_$])*$/, message: 'Please Compliant key'
                                },
                                {
                                  validator: (rule, value, callback) =>{
                                    validateKeyUnique(item, value, callback, step2_form, 'clinicalPlaceFields');
                                  },
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="4" :sm="12">
                      <a-form-item class="mb-10" label="Field Type" :colon="false">
                        <a-select
                            style="width: 100%"
                            show-search
                            optionFilterProp="title"
                            placeholder="Please select"
                            v-decorator="[
                              'clinicalPlaceFields['+item+'].type',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select field type!'
                                  },
                                ],
                              }
                            ]"
                        >
                          <a-select-option v-for="item in fieldTypes" :key="item.value"
                                           :title="item.title">
                            {{ item.title }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="6" :sm="12">
                      <a-form-item class="mb-10" label="Required" :colon="false">
                        <a-radio-group
                            v-decorator="[
                              'clinicalPlaceFields['+item+'].required',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select!'
                                  },
                                ],
                                initialValue: true,
                              }
                            ]"
                        >
                          <a-radio :value="true">
                            Yes
                          </a-radio>
                          <a-radio :value="false">
                            No
                          </a-radio>
                        </a-radio-group>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24"
                           v-if="step2_form.getFieldValue('clinicalPlaceFields['+item+'].type')==='radio' || step2_form.getFieldValue('clinicalPlaceFields['+item+'].type')==='checkBox'">
                      <a-form-item class="mb-10" label="Values" :colon="false">
                        <a-select mode="tags" style="width: 100%" placeholder="Please enter options and press enter"
                                  v-decorator="[
                              'clinicalPlaceFields['+item+'].values',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please enter values!'
                                  },
                                ],
                              }
                            ]">
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-card>

              </draggable>

              <a-row type="flex" :gutter="[24]" class="flex-justify-content-center"
                     :class="clinicalPlaceFieldIndices.length<=0 ? 'my-50' : ''">
                <a-col :span="24" :sm="12">
                  <a-form-item
                      class="mb-10 text-center"
                      :colon="false">
                    <a-button type="dashed" @click="addNewClinicalPlaceField" class="px-20">
                      <a-icon type="plus"/>
                      Add Field
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
            <div class="mt-30">
              <h5 class="mb-10">CRO Entry staff Entry Fields</h5>
              <draggable :list="croClinicalPlaceFieldIndices" :animation="200" ghost-class="ghost-card"
                         handle=".kanban-card__move_btn"
                         group="croClinicalPlaceFieldIndices">
                <a-card
                    v-for="(item, index) in croClinicalPlaceFieldIndices"
                    :key="`croClinicalPlaceField_${item}`"
                    :bordered="false"
                    class="kanban-card mb-24 position-relative"
                >

                  <a-button v-if="croClinicalPlaceFieldIndices.length>1"
                            @click="removeCroClinicalPlaceField(index)"
                            class="position-absolute kanban-card__remove_btn" type="danger" shape="circle">
                    <a-icon type="delete" class="m-0 text-md"/>
                  </a-button>
                  <a-button
                      class="position-absolute kanban-card__move_btn" type="" shape="circle"
                      style="transform: rotate(90deg)">
                    <a-icon type="swap" class="m-0 text-md"/>
                  </a-button>
                  <a-row :gutter="[24]" type="flex">
                    <a-col :span="24" :md="7" :sm="12">
                      <a-form-item class="mb-10" label="Title" :colon="false">
                        <a-input
                            placeholder="eg. Street 111"
                            v-decorator="[
                            'croClinicalPlaceFields['+item+'].title',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please input title!'
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>

                    <a-col :span="24" :md="7" :sm="12">
                      <a-form-item class="mb-10" label="Key (a-z, A-Z, _, $)" :colon="false">
                        <a-input
                            placeholder="eg. Street 111"
                            v-decorator="[
                            'croClinicalPlaceFields['+item+'].key',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please input key!'
                                },
                                {
                                  pattern: /^([a-zA-Z_$])([a-zA-Z0-9_$])*$/, message: 'Please Compliant key'
                                },
                                {
                                  validator: (rule, value, callback) =>{
                                    validateKeyUnique(item, value, callback, step2_form, 'croClinicalPlaceFields');
                                  },
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="4" :sm="12">
                      <a-form-item class="mb-10" label="Field Type" :colon="false">
                        <a-select
                            show-search
                            style="width: 100%"
                            optionFilterProp="title"
                            placeholder="Please select"
                            v-decorator="[
                              'croClinicalPlaceFields['+item+'].type',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select field type!'
                                  },
                                ],
                              }
                            ]"
                        >
                          <a-select-option v-for="item in fieldTypes" :key="item.value"
                                           :title="item.title">
                            {{ item.title }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="6" :sm="12">
                      <a-form-item class="mb-10" label="Required" :colon="false">
                        <a-radio-group
                            v-decorator="[
                              'croClinicalPlaceFields['+item+'].required',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select!'
                                  },
                                ],
                                initialValue: true,
                              }
                            ]"
                        >
                          <a-radio :value="true">
                            Yes
                          </a-radio>
                          <a-radio :value="false">
                            No
                          </a-radio>
                        </a-radio-group>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24"
                           v-if="step2_form.getFieldValue('croClinicalPlaceFields['+item+'].type')==='radio' || step2_form.getFieldValue('croClinicalPlaceFields['+item+'].type')==='checkBox'">
                      <a-form-item class="mb-10" label="Values" :colon="false">
                        <a-select mode="tags" style="width: 100%" placeholder="Please enter options and press enter"
                                  v-decorator="[
                              'croClinicalPlaceFields['+item+'].values',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please enter values!'
                                  },
                                ],
                              }
                            ]">
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-card>

              </draggable>

              <a-row type="flex" :gutter="[24]" class="flex-justify-content-center"
                     :class="clinicalPlaceFieldIndices.length<=0 ? 'my-50' : ''">
                <a-col :span="24" :sm="12">
                  <a-form-item
                      class="mb-10 text-center"
                      :colon="false">
                    <a-button type="dashed" @click="addNewCroClinicalPlaceField" class="px-20">
                      <a-icon type="plus"/>
                      Add Field
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="12">
                <a-button @click="prevStep" class="px-25">PREV</a-button>
              </a-col>
              <a-col :span="12" class="text-right">
                <a-button type="primary" html-type="submit" class="px-25">NEXT</a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <!-- Step 4 -->
        <a-card v-show="step === 3" :bordered="false" class="header-solid kanban-board">
          <a-form
              :form="step3_form"
              @submit="nextStep"
          >

            <div>
              <h5 class="mb-10">Laboratory Entry Fields</h5>
              <draggable :list="laboratoryFieldIndices" :animation="200" ghost-class="ghost-card"
                         handle=".kanban-card__move_btn"
                         group="clinicalPlaceFieldIndices">
                <a-card
                    v-for="(item, index) in laboratoryFieldIndices"
                    :key="`laboratoryField_${item}`"
                    :bordered="false"
                    class="kanban-card mb-24 position-relative"
                >

                  <a-button v-if="laboratoryFieldIndices.length>1"
                            @click="removeLaboratoryField(index)"
                            class="position-absolute kanban-card__remove_btn" type="danger" shape="circle">
                    <a-icon type="delete" class="m-0 text-md"/>
                  </a-button>
                  <a-button
                      class="position-absolute kanban-card__move_btn" type="" shape="circle"
                      style="transform: rotate(90deg)">
                    <a-icon type="swap" class="m-0 text-md"/>
                  </a-button>
                  <a-row :gutter="[24]" type="flex">
                    <a-col :span="24" :md="7" :sm="12">
                      <a-form-item class="mb-10" label="Title" :colon="false">
                        <a-input
                            placeholder="eg. Street 111"
                            v-decorator="[
                            'laboratoryFields['+item+'].title',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please input title!'
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="7" :sm="12">
                      <a-form-item class="mb-10" label="Key (a-z, A-Z, _, $)" :colon="false">
                        <a-input
                            placeholder="eg. Street 111"
                            v-decorator="[
                            'laboratoryFields['+item+'].key',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please input key!'
                                },
                                {
                                  pattern: /^([a-zA-Z_$])([a-zA-Z0-9_$])*$/, message: 'Please Compliant key'
                                },
                                {
                                  validator: (rule, value, callback) =>{
                                    validateKeyUnique(item, value, callback, step3_form, 'laboratoryFields');
                                  },
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="4" :sm="12">
                      <a-form-item class="mb-10" label="Field Type" :colon="false">
                        <a-select
                            style="width: 100%"
                            show-search
                            optionFilterProp="title"
                            placeholder="Please select"
                            v-decorator="[
                              'laboratoryFields['+item+'].type',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select field type!'
                                  },
                                ],
                              }
                            ]"
                        >
                          <a-select-option v-for="item in fieldTypes" :key="item.value"
                                           :title="item.title">
                            {{ item.title }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="6" :sm="12">
                      <a-form-item class="mb-10" label="Required" :colon="false">
                        <a-radio-group
                            v-decorator="[
                              'laboratoryFields['+item+'].required',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select!'
                                  },
                                ],
                                initialValue: true,
                              }
                            ]"
                        >
                          <a-radio :value="true">
                            Yes
                          </a-radio>
                          <a-radio :value="false">
                            No
                          </a-radio>
                        </a-radio-group>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24"
                           v-if="step3_form.getFieldValue('laboratoryFields['+item+'].type')==='radio' || step3_form.getFieldValue('laboratoryFields['+item+'].type')==='checkBox'">
                      <a-form-item class="mb-10" label="Values" :colon="false">
                        <a-select mode="tags" style="width: 100%" placeholder="Please enter options and press enter"
                                  v-decorator="[
                              'laboratoryFields['+item+'].values',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please enter values!'
                                  },
                                ],
                              }
                            ]">
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-card>

              </draggable>

              <a-row type="flex" :gutter="[24]" class="flex-justify-content-center"
                     :class="laboratoryFieldIndices.length<=0 ? 'my-50' : ''">
                <a-col :span="24" :sm="12">
                  <a-form-item
                      class="mb-10 text-center"
                      :colon="false">
                    <a-button type="dashed" @click="addNewLaboratoryField" class="px-20">
                      <a-icon type="plus"/>
                      Add Field
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
            <div class="mt-30">
              <h5 class="mb-10">CRO Entry staff Entry Fields</h5>
              <draggable :list="croLaboratoryFieldIndices" :animation="200" ghost-class="ghost-card"
                         handle=".kanban-card__move_btn"
                         group="croClinicalPlaceFieldIndices">
                <a-card
                    v-for="(item, index) in croLaboratoryFieldIndices"
                    :key="`croLaboratoryField_${item}`"
                    :bordered="false"
                    class="kanban-card mb-24 position-relative"
                >

                  <a-button v-if="croLaboratoryFieldIndices.length>1"
                            @click="removeCroLaboratoryField(index)"
                            class="position-absolute kanban-card__remove_btn" type="danger" shape="circle">
                    <a-icon type="delete" class="m-0 text-md"/>
                  </a-button>
                  <a-button
                      class="position-absolute kanban-card__move_btn" type="" shape="circle"
                      style="transform: rotate(90deg)">
                    <a-icon type="swap" class="m-0 text-md"/>
                  </a-button>
                  <a-row :gutter="[24]" type="flex">
                    <a-col :span="24" :md="7" :sm="12">
                      <a-form-item class="mb-10" label="Title" :colon="false">
                        <a-input
                            placeholder="eg. Street 111"
                            v-decorator="[
                            'croLaboratoryFields['+item+'].title',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please input title!'
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="7" :sm="12">
                      <a-form-item class="mb-10" label="Key (a-z, A-Z, _, $)" :colon="false">
                        <a-input
                            placeholder="eg. Street 111"
                            v-decorator="[
                            'croLaboratoryFields['+item+'].key',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please input key!'
                                },
                                {
                                  pattern: /^([a-zA-Z_$])([a-zA-Z0-9_$])*$/, message: 'Please Compliant key'
                                },
                                {
                                  validator: (rule, value, callback) =>{
                                    validateKeyUnique(item, value, callback, step3_form, 'croLaboratoryFields');
                                  },
                                },
                              ],
                            },
                          ]"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="4" :sm="12">
                      <a-form-item class="mb-10" label="Field Type" :colon="false">
                        <a-select
                            style="width: 100%"
                            show-search
                            optionFilterProp="title"
                            placeholder="Please select"
                            v-decorator="[
                              'croLaboratoryFields['+item+'].type',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select field type!'
                                  },
                                ],
                              }
                            ]"
                        >
                          <a-select-option v-for="item in fieldTypes" :key="item.value"
                                           :title="item.title">
                            {{ item.title }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24" :md="6" :sm="12">
                      <a-form-item class="mb-10" label="Required" :colon="false">
                        <a-radio-group
                            v-decorator="[
                              'croLaboratoryFields['+item+'].required',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select!'
                                  },
                                ],
                                initialValue: true,
                              }
                            ]"
                        >
                          <a-radio :value="true">
                            Yes
                          </a-radio>
                          <a-radio :value="false">
                            No
                          </a-radio>
                        </a-radio-group>
                      </a-form-item>
                    </a-col>
                    <a-col :span="24"
                           v-if="step3_form.getFieldValue('croLaboratoryFields['+item+'].type')==='radio' || step3_form.getFieldValue('croLaboratoryFields['+item+'].type')==='checkBox'">
                      <a-form-item class="mb-10" label="Values" :colon="false">
                        <a-select mode="tags" style="width: 100%" placeholder="Please enter options and press enter"
                                  v-decorator="[
                              'croLaboratoryFields['+item+'].values',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please enter values!'
                                  },
                                ],
                              }
                            ]">
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-card>

              </draggable>

              <a-row type="flex" :gutter="[24]" class="flex-justify-content-center"
                     :class="croLaboratoryFieldIndices.length<=0 ? 'my-50' : ''">
                <a-col :span="24" :sm="12">
                  <a-form-item
                      class="mb-10 text-center"
                      :colon="false">
                    <a-button type="dashed" @click="addNewCroLaboratoryField" class="px-20">
                      <a-icon type="plus"/>
                      Add Field
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>
            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="12">
                <a-button @click="prevStep" class="px-25">PREV</a-button>
              </a-col>
              <a-col :span="12" class="text-right">
                <a-button type="primary" html-type="submit" class="px-25">NEXT</a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
        <!-- Step 5 -->
        <a-card v-show="step === 4" :bordered="false" class="header-solid">
          <a-form
              :form="step4_form"
              @submit="handleSubmit"
          >
            <div v-for="(item, index) in patientIndices" :key="'patient_'+item">
              <div class="d-flex mb-10 flex-align-items-center flex-justify-content-space-between">
                <h5 class="my-0 mr-5" style="min-height: 40px">Patient Range {{ index + 1 }}</h5>
                <a-button v-if="patientIndices.length>1" @click="removePatient(index)" type="link">
                  <a-icon type="delete" theme="twoTone" class="m-0 text-xl " two-tone-color="#eb2f96"/>
                </a-button>
              </div>
              <a-row :gutter="[24]" type="flex">
                <a-col :span="24" :sm="12" :md="8">
                  <a-form-item class="mb-10" label="Product" :colon="false">

                    <a-select
                        style="width: 100%"
                        show-search
                        optionFilterProp="title"
                        placeholder="Please select"
                        v-decorator="[
                              'patientRanges['+item+'].productIndex',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select field type!'
                                  },
                                ],
                              }
                            ]"
                    >
                      <template
                          v-if="products&&products.length>0">
                        <template v-for="(item, index) in products">
                          <a-select-option v-if="null!=item" :key="index"
                                           :title="`${item['productName']} (#${item['productNo']})`">
                            {{ item['productName'] }} (#{{ item['productNo'] }})
                          </a-select-option>
                        </template>
                      </template>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12" :md="8">
                  <a-form-item class="mb-10" label="Site" :colon="false">

                    <a-select
                        show-search
                        optionFilterProp="title"
                        style="width: 100%"
                        placeholder="Please select"
                        v-decorator="[
                              'patientRanges['+item+'].clinicalPlaceId',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select field type!'
                                  },
                                ],
                              }
                            ]"
                    >
                      <a-select-option v-for="item in allClinicalPlace" :key="item.id"
                                       :title="item.clinicalPlaceName + ' (#'+item.clinicalPlaceNo+')'">
                        {{ item.clinicalPlaceName }} (#{{ item.clinicalPlaceNo }})
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12" :md="8">
                  <a-form-item class="mb-10" label="Laboratory" :colon="false">

                    <a-select
                        show-search
                        optionFilterProp="title"
                        style="width: 100%"
                        placeholder="Please select"
                        v-decorator="[
                              'patientRanges['+item+'].laboratoryId',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select field type!'
                                  },
                                ],
                              }
                            ]"
                    >
                      <a-select-option v-for="item in allLaboratory" :key="item.id"
                                       :title="item.laboratoryName + ' (#'+item.laboratoryNo+')'">
                        {{ item.laboratoryName }} (#{{ item.laboratoryNo }})
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="[24]" type="flex">
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Patient # begin" :colon="false">
                    <div class="ant-input-group-wrapper">
                      <div class="ant-input-wrapper ant-input-group">
                        <span class="ant-input-group-addon">
                          <div :key="`patient_begin_addon_${item}`">
                            {{
                              step0_form.getFieldValue('projectNo') ? step0_form.getFieldValue('projectNo') : 'XX'
                            }}-{{
                              (() => {
                                const temp = parseInt(step4_form.getFieldValue('patientRanges[' + item + '].productIndex'));
                                return temp >= 0 ? products[temp].productNo : 'XX'
                              })()
                            }}{{
                              (() => {
                                const temp = parseInt(step4_form.getFieldValue('patientRanges[' + item + '].clinicalPlaceId'));
                                return temp >= 0 ? allClinicalPlace.find((e) => e.id === temp).clinicalPlaceNo : 'XX'
                              })()
                            }}-
                          </div>
                        </span>

                        <a-input-number
                            class="ant-input"
                            placeholder="#"
                            :min="0"
                            :precision="0"
                            v-decorator="[
                              'patientRanges['+item+'].patientNoStart',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please input Patient # begin!'
                                  },
                                  {
                                    validator: (rule, value, callback) =>{
                                      const patientRanges = step4_form.getFieldValue('patientRanges');
                                      if(!patientRanges[item].productIndex){
                                        step4_form.validateFieldsAndScroll(['patientRanges['+item+'].productIndex']);
                                      }
                                      callback();
                                    },
                                  },
                                  {
                                    validator: (rule, value, callback) =>{
                                      const patientRanges = step4_form.getFieldValue('patientRanges');
                                      if(!patientRanges[item].clinicalPlaceId){
                                        step4_form.validateFieldsAndScroll(['patientRanges['+item+'].clinicalPlaceId']);
                                      }
                                      callback();
                                    },
                                  },
                                  {
                                    validator: (rule, value, callback) =>{

                                      const patientRanges = step4_form.getFieldValue('patientRanges');
                                      const curr = patientRanges[item];
                                      const hasValuePatientRanges = patientRanges.map((e, index)=>e ? { ...e, index} : null).filter(e=>e).filter(e=>e.productIndex===curr.productIndex&&e.clinicalPlaceId===curr.clinicalPlaceId);

                                      const currIndex = hasValuePatientRanges.findIndex(e => e.index === item);

                                      if(currIndex===0){
                                        callback();
                                        return;
                                      }

                                      if(!hasValuePatientRanges[currIndex-1].patientNoEnd && hasValuePatientRanges[currIndex-1].patientNoEnd!==0){
                                        // step4_form.validateFieldsAndScroll(['patientRanges['+hasValuePatientRanges[currIndex-1].index+'].patientNoEnd']);
                                        callback('Please enter the end of the previous item (same product, same site) first');
                                        return;
                                      }

                                      if (value<=hasValuePatientRanges[currIndex-1].patientNoEnd) {
                                        callback('Must be greater than the end of the previous item (same product, same site)');
                                        return;
                                      }

                                      callback();
                                    },
                                  },
                                  {
                                    validator: (rule, value, callback) =>{
                                      const patientRanges = step4_form.getFieldValue('patientRanges');
                                      if(patientRanges[item].patientNoEnd>=0){
                                        step4_form.validateFieldsAndScroll(['patientRanges['+item+'].patientNoEnd'])
                                      }
                                      callback();
                                    },
                                  },
                                ],
                              },
                            ]"
                        >
                        </a-input-number>
                      </div>
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Patient # end" :colon="false">
                    <div class="ant-input-group-wrapper">
                      <div class="ant-input-wrapper ant-input-group">
                        <span class="ant-input-group-addon">
                          <div :key="`patient_end_addon_${item}`">
                            {{
                              step0_form.getFieldValue('projectNo') ? step0_form.getFieldValue('projectNo') : 'XX'
                            }}-{{
                              (() => {
                                const temp = parseInt(step4_form.getFieldValue('patientRanges[' + item + '].productIndex'));
                                return temp >= 0 ? products[temp].productNo : 'XX'
                              })()
                            }}{{
                              (() => {
                                const temp = parseInt(step4_form.getFieldValue('patientRanges[' + item + '].clinicalPlaceId'));
                                return temp >= 0 ? allClinicalPlace.find((e) => e.id === temp).clinicalPlaceNo : 'XX'
                              })()
                            }}-
                          </div>
                        </span>

                        <a-input-number
                            class="ant-input"
                            placeholder="#"
                            :min="0"
                            :precision="0"
                            v-decorator="[
                              'patientRanges['+item+'].patientNoEnd',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please input Patient # end!'
                                  },
                                  {
                                    validator: (rule, value, callback) =>{
                                      const patientRanges = step4_form.getFieldValue('patientRanges');
                                      if(!patientRanges[item].productIndex){
                                        step4_form.validateFieldsAndScroll(['patientRanges['+item+'].productIndex']);
                                      }
                                      callback();
                                    },
                                  },
                                  {
                                    validator: (rule, value, callback) =>{
                                      const patientRanges = step4_form.getFieldValue('patientRanges');
                                      if(!patientRanges[item].clinicalPlaceId){
                                        step4_form.validateFieldsAndScroll(['patientRanges['+item+'].clinicalPlaceId']);
                                      }
                                      callback();
                                    },
                                  },
                                  {
                                    validator: (rule, value, callback) =>{
                                      const patientRanges = step4_form.getFieldValue('patientRanges');
                                      if(!patientRanges[item].patientNoStart && patientRanges[item].patientNoStart !==0){
                                        // step4_form.validateFieldsAndScroll(['patientRanges['+item+'].patientNoStart']);
                                        callback('Please enter begin first!');
                                        return;
                                      }

                                      callback();
                                    },
                                  },
                                  {
                                    validator: (rule, value, callback) =>{
                                      const patientRanges = step4_form.getFieldValue('patientRanges');

                                      if(value < patientRanges[item].patientNoStart){
                                        callback('Cannot be less than begin');
                                        return;
                                      }

                                      callback();
                                    },
                                  },
                                  {
                                    validator: (rule, value, callback) =>{
                                      const patientRanges = step4_form.getFieldValue('patientRanges');
                                      const curr = patientRanges[item];
                                      const hasValuePatientRanges = patientRanges.map((e, index)=>e ? { ...e, index} : null).filter(e=>e).filter(e=>e.productIndex===curr.productIndex&&e.clinicalPlaceId===curr.clinicalPlaceId);

                                      const currIndex = hasValuePatientRanges.findIndex(e => e.index === item);

                                      if(hasValuePatientRanges[currIndex+1] && hasValuePatientRanges[currIndex+1].patientNoStart>=0){
                                        step4_form.validateFieldsAndScroll(['patientRanges['+hasValuePatientRanges[currIndex+1].index+'].patientNoStart'])
                                      }
                                      callback();
                                    },
                                  },
                                ],
                              },
                            ]"
                        >
                        </a-input-number>
                      </div>
                    </div>
                  </a-form-item>
                </a-col>
              </a-row>
              <hr class="c-hr"/>
            </div>

            <a-row type="flex" :gutter="[24]" class="flex-justify-content-center"
                   :class="patientIndices.length<=0 ? 'my-50' : ''">
              <a-col :span="24" :sm="12">
                <a-form-item
                    class="mb-10 text-center"
                    :colon="false">
                  <a-button type="dashed" @click="addNewPatient" class="px-20">
                    <a-icon type="plus"/>
                    Add New patient range
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>


            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="12">
                <a-button @click="prevStep" class="px-25">PREV</a-button>
              </a-col>
              <a-col :span="12" class="text-right">
                <a-button type="primary" html-type="submit" class="px-25" :loading="submitBtnLoading">Add</a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </div>
    </a-loading>

  </div>

</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      step: 0,
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true,
      step0_form: this.$form.createForm(this, {name: 'step0_form'}),
      step1_form: this.$form.createForm(this, {name: 'step1_form'}),
      step2_form: this.$form.createForm(this, {name: 'step2_form'}),
      step3_form: this.$form.createForm(this, {name: 'step3_form'}),
      step4_form: this.$form.createForm(this, {name: 'step4_form'}),
      pageLoadingState: 'loading',
      croEpAndQcs: [],
      allClinicalPlace: [],
      allLaboratory: [],
      productCustomIndices: [0],
      clinicalPlaceFieldIndices: [0],
      croClinicalPlaceFieldIndices: [0],
      laboratoryFieldIndices: [0],
      croLaboratoryFieldIndices: [0],
      patientIndices: [0],
      fieldTypes: [
        {
          value: 'string',
          title: 'String',
        },
        {
          value: 'int',
          title: 'Integer',
        },
        {
          value: 'float',
          title: 'Float',
        },
        {
          value: 'date',
          title: 'Date',
        },
        {
          value: 'time',
          title: 'Time',
        },
        {
          value: 'dateTime',
          title: 'Datetime',
        },
        {
          value: 'boolean',
          title: 'Boolean',
        },
        {
          value: 'radio',
          title: 'Radio',
        },
        {
          value: 'checkBox',
          title: 'Check Box',
        },
        {
          value: 'pdfFile',
          title: 'PDF File',
        },
        {
          value: 'imageFile',
          title: 'Image File',
        },
        {
          value: 'excelFile',
          title: 'Excel File',
        },
        {
          value: 'wordFile',
          title: 'Word File',
        },
        {
          value: 'pptFile',
          title: 'PPT File',
        },
        {
          value: 'compressedFile',
          title: 'Compressed File',
        },
        {
          value: 'otherFile',
          title: 'Other File (Warning: It may endanger system security, please choose carefully)',
        },
      ],
      projectFileList: [],
      projectComplianceFileList: [],
      submitBtnLoading: false,
      products: [],
    }
  },
  async created() {

    this.pageLoadingState = 'loading';



    let result = await this.$axios.batchPost({
      urls: [
        {
          url: '/ctcClinicalPlace/query/listAll',
        },
        {
          url: '/ctcLaboratory/query/listAll',
        },
        {
          url: '/ctcUser/query/listCroEpAndQc',
        },
      ],
    });
    if (result.success) {
      this.allClinicalPlace = result.body[0].body;
      this.allLaboratory = result.body[1].body;
      this.croEpAndQcs = result.body[2].body;
      this.pageLoadingState = 'success';
    } else {
      this.pageLoadingState = 'failed';
    }

  },
  methods: {
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();

      const values0 = await this.$fvs.validateFieldsAndScroll(this.step0_form);
      if (!values0) {
        this.$message.error("Project Info incomplete data.");
        this.step = 0;
        return;
      }
      const values1 = await this.$fvs.validateFieldsAndScroll(this.step1_form);
      if (!values1) {
        this.$message.error("Products incomplete data.");
        this.step = 1;
        return;
      }
      const values2 = await this.$fvs.validateFieldsAndScroll(this.step2_form);
      if (!values2) {
        this.$message.error("Site incomplete data.");
        this.step = 2;
        return;
      }
      const values3 = await this.$fvs.validateFieldsAndScroll(this.step3_form);
      if (!values3) {
        this.$message.error("Laboratory incomplete data.");
        this.step = 3;
        return;
      }
      const values4 = await this.$fvs.validateFieldsAndScroll(this.step4_form);
      if (!values4) {
        this.$message.error("Patients incomplete data.");
        this.step = 4;
        return;
      }
      let params = {
        ...{
          ...values0,
          ...(this.projectFileList && this.projectFileList.length > 0 ? {projectFiles: JSON.stringify(this.projectFileList)} : {}),
          ...(this.projectComplianceFileList && this.projectComplianceFileList.length > 0 ? {projectComplianceFiles: JSON.stringify(this.projectComplianceFileList)} : {}),
        },
        ...{
          ...values1,
        },
        ...{
          ...values2,
        },
        ...{
          ...values3,
        },
        ...{
          ...values4,
        },
      };
      let params2 = JSON.parse(JSON.stringify(params));
      // console.log(params2.products);
      // console.log(params2.products.map(e => (e ? e : {isRemoved: true})));
      // console.log(params2);
      params2 = {
        ...params2,
        products: params2.products.map(e => (e ? e : {isRemoved: true})),
        clinicalPlaceFields: params2.clinicalPlaceFields.filter(e => e),
        croClinicalPlaceFields: params2.croClinicalPlaceFields.filter(e => e),
        laboratoryFields: params2.laboratoryFields.filter(e => e),
        croLaboratoryFields: params2.croLaboratoryFields.filter(e => e),
        patientRanges: params2.patientRanges.filter(e => e),
      }

      // console.log(params2);

      this.submitBtnLoading = true;

      let result = await this.$axios.post({
        url: '/ctcProject/add',
        data: params2,
      });

      if (result.success) {
        const that = this;
        this.$message.success("Project add success.").then(()=>{
          that.submitBtnLoading = false;
          that.$router.replace("/basic-manage/trial-manage/trial-list");
        });

      } else {
        this.submitBtnLoading = false;
      }

    },
    async nextStep(e) {
      e.preventDefault();

      switch (this.step) {
        case 0:
          const values = await this.$fvs.validateFieldsAndScroll(this.step0_form);
          if (!values) {
            return;
          }
          break;
        case 1:
          if (this.productCustomIndices.length <= 0) {
            break;
          }
          const values1 = await this.$fvs.validateFieldsAndScroll(this.step1_form);
          if (!values1) {
            return;
          }
          // console.log(values1);
          // console.log(values1.products.filter(function (s) {
          //   return s;
          // }));
          const patientRanges = this.step4_form.getFieldValue('patientRanges');

          for (let i = 0; i < patientRanges.length; i++) {
            if (!patientRanges[i]) {
              continue;
            }
            this.step4_form.resetFields([`patientRanges[${i}].productIndex`]);
          }

          this.products = JSON.parse(JSON.stringify(values1.products));

          break;
        case 2:
          const values2 = await this.$fvs.validateFieldsAndScroll(this.step2_form);
          if (!values2) {
            return;
          }
          break;
        case 3:
          const values3 = await this.$fvs.validateFieldsAndScroll(this.step3_form);
          if (!values3) {
            return;
          }
          break;
        default:
          return;
      }

      this.step++;
    },
    async prevStep() {
      this.step--;
    },
    handleUploadChange(info, fileList) {
      const {file} = info;
      console.log("info", info);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const {response} = file;
        const {body, code, msg, success} = response;

        if(code !== 'SUCCESS' || !success){
          this.$message.error(`${info.file.name} file upload failed. ${msg}`);
          return;
        }
        if (parseInt(code) === 100000 || code === '100000') {
          localStorage.removeItem("HMC_ROLES");
          localStorage.removeItem("HMC_USER");
          window.location.reload();
          return;
        }

        fileList.push({
          uid: file.uid,
          status: 'done',
          ...body,
        });

        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === "removed") {
        const foundIndex = fileList.findIndex((e) => e.uid === file.uid);
        if (foundIndex >= 0) {
          fileList.splice(foundIndex, 1);
          this.$message.success(`${info.file.name} file removed.`);
        } else {
          this.$message.error(`${info.file.name} file not found, failed to remove.`);
        }
      }
      console.log("fileList", fileList);
    },
    addMaxValToArray(list) {
      let currIndex = -1;

      if (list && list.length > 0) {
        currIndex = list[list.length - 1];
      }

      list.push(currIndex + 1);
    },
    removeByIndexOfArray(list, index) {
      if (list.length <= 1) {
        return;
      }
      list.splice(index, 1);
    },
    addNewProduct() {
      this.addMaxValToArray(this.productCustomIndices);
    },
    removeProduct(index) {
      this.removeByIndexOfArray(this.productCustomIndices, index);
    },
    addNewClinicalPlaceField() {
      this.addMaxValToArray(this.clinicalPlaceFieldIndices);
    },
    removeClinicalPlaceField(index) {
      this.removeByIndexOfArray(this.clinicalPlaceFieldIndices, index);
    },
    addNewCroClinicalPlaceField() {
      this.addMaxValToArray(this.croClinicalPlaceFieldIndices);
    },
    removeCroClinicalPlaceField(index) {
      this.removeByIndexOfArray(this.croClinicalPlaceFieldIndices, index);
    },
    addNewLaboratoryField() {
      this.addMaxValToArray(this.laboratoryFieldIndices);
    },
    removeLaboratoryField(index) {
      this.removeByIndexOfArray(this.laboratoryFieldIndices, index);
    },
    addNewCroLaboratoryField() {
      this.addMaxValToArray(this.croLaboratoryFieldIndices);
    },
    removeCroLaboratoryField(index) {
      this.removeByIndexOfArray(this.croLaboratoryFieldIndices, index);
    },
    addNewPatient() {
      this.addMaxValToArray(this.patientIndices);
    },
    removePatient(index) {
      this.removeByIndexOfArray(this.patientIndices, index);
    },
    validateKeyUnique(index, value, callback, form, fieldName) {
      const list = form.getFieldValue(fieldName);

      for (let i = 0; i < list.length; i++) {
        if (i === index || !list[i]) {
          continue;
        }
        if (list[i].key === value) {
          callback('key must be unique');
          return;
        }
      }
      callback();
    },
    validateProductNoUnique(index, value, callback, form, fieldName) {
      const list = form.getFieldValue(fieldName);

      for (let i = 0; i < list.length; i++) {
        if (i === index || !list[i]) {
          continue;
        }
        if (list[i].productNo === value) {
          callback('Product # must be unique');
          return;
        }
      }
      callback();
    },
  },
}

</script>

<style lang="scss" scoped>

.kanban-board {
  box-shadow: none;
  background: #e9ecef;

  .kanban-card {

    .kanban-card__remove_btn {
      right: -8.5px;
      top: -8.5px;
      z-index: 10;
      opacity: 0;
      min-width: 34px;
      width: 34px;
      height: 34px;
    }

    .kanban-card__move_btn {
      left: calc(50% - 17px);
      bottom: -17px;
      z-index: 10;
      opacity: 0;
      min-width: 34px;
      width: 34px;
      height: 34px;
      cursor: move;
    }

    &:hover {
      .kanban-card__remove_btn {
        opacity: 1;
      }

      .kanban-card__move_btn {
        opacity: 1;
      }
    }
  }
}

.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px dashed #4299e1;

  .kanban-card__remove_btn {
    display: none;
  }

  .kanban-card__move_btn {
    display: none;
  }
}
</style>
