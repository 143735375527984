<!--
	This is the new user page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>

    <div class="mb-24 mx-auto" style="max-width: 800px">
      <a-card :bordered="false" class="header-solid">
        <a-loading :state="pageLoadingState">
          <a-form
              :form="form"
              @submit="handleSubmit"
          >
            <h5 class="mt-5">Site information</h5>
            <a-row type="flex" :gutter="[24]">
              <a-col :span="24" :sm="12">
                <a-form-item :required="true" class="mb-10" label="Site #" :colon="false">
                  <a-input

                      v-decorator="[
                      'laboratoryNo',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input #!'
                          },
                        ],
                        initialValue: updateOriginal.laboratory.laboratoryNo,
                      },
                    ]"
                      placeholder="eg. Michael"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item :required="true" class="mb-10" label="Name" :colon="false">
                  <a-input

                      v-decorator="[
                      'laboratoryName',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input Name!'
                          },
                        ],
                        initialValue: updateOriginal.laboratory.laboratoryName,
                      },
                    ]"
                      placeholder="eg. Michael"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Address" :colon="false">
                  <a-textarea
                      placeholder="Please input Address"
                      :auto-size="{ minRows: 3, maxRows: 6 }"
                      v-decorator="[
                      'laboratoryAddress',
                      {
                        initialValue: updateOriginal.laboratory.laboratoryAddress,
                      },
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Introduce" :colon="false">
                  <a-textarea
                      placeholder="Please input Introduce"
                      :auto-size="{ minRows: 3, maxRows: 6 }"
                      v-decorator="[
                      'laboratoryIntroduce',
                      {
                        initialValue: updateOriginal.laboratory.laboratoryIntroduce,
                      },
                    ]"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="mt-20">Staff</h5>
            <a-row type="flex" :gutter="[24]">
              <a-col :span="24" :sm="12">
                <a-form-item
                    class="mb-10"
                    label="Staff"
                    optionFilterProp="title"
                    :colon="false">
                  <a-select
                      mode="multiple"
                      style="width: 100%"
                      optionFilterProp="title"
                      placeholder="Please select"
                      v-decorator="[
                      'userIds',
                      {
                        initialValue: updateOriginal.userIds,
                      },
                    ]"
                  >
                    <a-select-option v-for="item in labEps" :key="item.id"
                                     :title="item.username + ' (#'+item.userNo+') - ['+item.roleNameAndIdsStr+']'">
                      {{ item.username }} (#{{ item.userNo }}) - [{{item.roleNameAndIdsStr}}]
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>


            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="24" class="text-right">
                <a-button type="link" @click="form.resetFields()" class="px-25 mr-15">Reset</a-button>
                <a-button type="primary" :loading="submitBtnLoading" html-type="submit"
                          class="px-25">Update
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-loading>

      </a-card>
    </div>

  </div>

</template>

<script>

export default {
  components: {},
  data() {
    return {
      form: this.$form.createForm(this, {name: 'newUserForm'}),
      submitBtnLoading: false,
      pageLoadingState: 'loading',
      labEps: [],
      updateOriginal:{
        laboratory: {},
        userIds:[],
      },
    }
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    // this.form = this.$form.createForm(this, {name: 'user'});
  },
  async created() {
    this.pageLoadingState = 'loading';

    let result2 = await this.$axios.post({
      url: '/ctcUser/query/listLabEp',
    });
    if (!result2.success) {
      this.pageLoadingState = 'failed';
    }
    this.labEps = result2.body;

    await this.loadData();
    const that = this;
    // window.setTimeout(()=>{
    //   console.log('aaa')
    //   that.form.resetFields();
    // }, 5000);
    // console.log(this.form)

  },
  methods: {
    async loadData(){

      let result3 = await this.$axios.post({
        url: '/ctcLaboratory/query/loadById',
        data: {
          id: this.$route.params.id,
        },
      });

      if (result3.success) {
        this.updateOriginal = result3.body;
        this.pageLoadingState = 'success';
      } else {
        this.pageLoadingState = 'failed';
      }
    },
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();
      const that = this;


      const values = await this.$fvs.validateFieldsAndScroll(this.form);
      if (!values) {
        return;
      }

      that.submitBtnLoading = true;
      let result = await that.$axios.post({
        url: '/ctcLaboratory/update/byId',
        data: {
          ...values,
          id: this.$route.params.id,
        },
      });

      if (result.success) {
        that.$message.success("update successful");

        this.pageLoadingState = 'loading';
        await this.loadData();

      }

      that.submitBtnLoading = false;

    },
  },
}

</script>

<style lang="scss">
</style>
