<template>
  <a-form-item class="mb-10" :label="title" :colon="false">
    <div class="h-5"></div>
    <a-upload-dragger
        :accept="fileTypeAcceptMap[type]"
        name="file"
        :multiple="true"
        :withCredentials="true"
        action="/api/ctcProject/upload/file"
        @change="(info)=>handleUploadChange(info)"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox"/>
      </p>
      <p class="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p class="ant-upload-hint">
        Support for a single or bulk upload.
      </p>
    </a-upload-dragger>
    <a-input
        type="hidden"
        :placeholder="`Please upload ${title}`"
        v-decorator="[
            keyName,
            {
              rules: [
                {
                  required: required,
                  message: `Please upload ${title}.`,
                },
              ],
            },
          ]"/>
  </a-form-item>
</template>

<script>
export default {
  props: {
    keyName: {
      type: String,
      default: "-", // loading, empty, failed
    },
    title: {
      type: String,
      default: "-", // loading, empty, failed
    },
    type: {
      type: String,
      default: "String", // loading, empty, failed
    },
    required: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
    },
  },
  data() {
    return {
      fileTypeAcceptMap: {
        pdfFile: '.pdf',
        imageFile: 'image/*',
        excelFile: '.xls,.xlsx',
        wordFile: '.doc,.docx',
        pptFile: '.ppt,.pptx',
        compressedFile: '.zip,.rar,.7z',
        otherFile: '*',
      },
      fileList: [],
    };
  },
  methods: {
    handleUploadChange(info) {
      const {file} = info;
      console.log("info", info);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const {response} = file;
        const {body, code, msg, success} = response;

        if(code !== 'SUCCESS' || !success){
          this.$message.error(`${info.file.name} file upload failed. ${msg}`);
          return;
        }
        if (parseInt(code) === 100000 || code === '100000') {
          localStorage.removeItem("HMC_ROLES");
          localStorage.removeItem("HMC_USER");
          window.location.reload();
          return;
        }

        this.fileList.push({
          uid: file.uid,
          status: 'done',
          ...body,
        });

        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === "removed") {
        const foundIndex = this.fileList.findIndex((e) => e.uid === file.uid);
        if (foundIndex >= 0) {
          this.fileList.splice(foundIndex, 1);
          this.$message.success(`${info.file.name} file removed.`);
        } else {
          this.$message.error(`${info.file.name} file not found, failed to remove.`);
        }
      }
      let temp = {};
      temp[this.keyName] = this.fileList && this.fileList.length > 0 ? JSON.stringify(this.fileList) : '';
      this.form.setFieldsValue(temp);
      console.log(this.form);
      console.log("fileList", this.fileList);
      this.form.validateFieldsAndScroll([this.keyName]);
    },
  },
}
</script>

<style scoped>

</style>