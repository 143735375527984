<template>

  <!-- Project 2 Card -->
  <a-card :bordered="false" class="card-project-2 header-solid" :bodyStyle="{paddingTop: '14px' }"
          :headStyle="{paddingBottom: '0' }">
    <template #title>
      <a-row type="flex" :gutter="[24,24]" align="middle">
        <a-col>
          <a-avatar
              :size="74"
              shape="square"
              style="border-radius: 8px; background-image: linear-gradient( 310deg, #141727, #3a416f );"
              v-if="logoType=='img'"
          >
            <img :src="logo" style="width: 100%;" alt="">
          </a-avatar>
          <a-avatar
              :size="74"
              shape="square"
              style="border-radius: 8px; background-image: linear-gradient( 310deg, #141727, #3a416f );"
              v-else
          >
            {{ logo }}
          </a-avatar>
        </a-col>
        <a-col>
          <h6 class="font-semibold mb-10">{{ title }}</h6>
          <div class="d-flex flex-align-items-center">
            <div :class="team && team.length>0 ? 'mr-5' : ''">
              <a-space :size="-11" class="avatar-chips">
                <template v-if="teamAvatarType=='img'">
                  <a-avatar size="small" v-for="(src, key) in team" :key="key" :src="src"/>
                </template>
                <template v-else>
                  <a-avatar size="small" v-for="(src, key) in team" :key="key"
                            :style="{'background-color': randomColors[Math.floor(Math.random()*10)] }">
                    {{ src }}
                  </a-avatar>
                </template>
              </a-space>
            </div>
            <div class="flex-1 font-size-12">
              <div :class="teamRightDanger ? 'text-danger' : ''">{{teamRight}}</div>
            </div>
          </div>
        </a-col>
        <a-col class="ml-auto pt-10" style="align-self: flex-start;">
          <slot name="more"></slot>
        </a-col>
      </a-row>
    </template>
    <p>
      <slot></slot>
    </p>
    <template v-for="item in footers">

      <hr>
      <a-row type="flex" :gutter="[24,24]" align="middle">
        <a-col>
          <h6 class="font-semibold text-md mb-0">{{ item.leftTitle }}</h6>
          <p class="font-semibold text-muted mb-0">
            <template v-if="item.leftUrl">
              <router-link :to="item.leftUrl">{{ item.left }}</router-link>
            </template>
            <template v-else>{{ item.left }}</template>
          </p>
        </a-col>
        <a-col class="ml-auto text-right">
          <h6 class="font-semibold text-md mb-0">{{ item.rightTitle }}</h6>
          <p class="font-semibold text-muted mb-0">
            <template v-if="item.rightUrl">
              <router-link :to="item.rightUrl">{{ item.right }}</router-link>
            </template>
            <template v-else>{{ item.right }}</template>
          </p>
        </a-col>
      </a-row>

    </template>
  </a-card>
  <!-- / Project 2 Card -->

</template>

<script>

export default ({
  props: {
    title: {
      type: String,
      default: "",
    },
    logo: {
      type: String,
      default: "",
    },
    logoType: {
      type: String,
      default: "img",
    },
    team: {
      type: Array,
      default: () => [],
    },
    teamRight: {
      type: String,
      default: "",
    },
    teamRightDanger: {
      type: Boolean,
      default: false,
    },
    teamAvatarType: {
      type: String,
      default: "img",
    },
    footers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      randomColors: [
        '#979844',
        '#3F6695',
        '#323332',
        '#666666',
        '#7199C8',
        '#666694',
        '#4397C7',
        '#A4CBCC',
        '#3F6695',
        '#A4CB50',
      ],
    }
  },
  created() {
    // for (let i = 0; i < 100; i++) {
    //   this.randomColors.push(this.randomBackgroundColor());
    // }
    // console.log(this.randomColors);
  },
  methods: {},
})

</script>
