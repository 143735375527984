<!--
	This is the new user page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div class="mx-auto mt-50" style="max-width: 1024px;">

    <a-loading :state="pageLoadingState">
      <div class="mb-50">
        <!-- Step 5 -->
        <a-card :bordered="false" class="header-solid">
          <a-form
              :form="step4_form"
              @submit="handleSubmit"
          >
            <div>
              <h5 class="mt-5">Basis</h5>
              <a-row :gutter="[24]" type="flex">

                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Trial" :colon="false">

                    <a-select
                        style="width: 100%"
                        show-search
                        optionFilterProp="title"
                        placeholder="Please select"
                        @select="onProjectSelect"
                        @deselect="onProjectDeselect"
                        v-decorator="[
                              'projectId',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select!'
                                  },
                                ],
                              }
                            ]"
                    >
                      <template v-for="(item, index) in allProjects">
                        <a-select-option :key="item.id"
                                         :title="`${item['projectName']} (#${item['projectNo']})`">
                          {{ item['projectName'] }} (#{{ item['projectNo'] }})
                        </a-select-option>
                      </template>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12" v-if="projectIsSelected">
                  <a-form-item class="mb-10" label="Device" :colon="false">
                    <a-select
                        style="width: 100%"
                        show-search
                        optionFilterProp="title"
                        placeholder="Please select"
                        v-decorator="[
                              'productId',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please select!'
                                  },
                                ],
                              }
                            ]"
                    >
                      <template
                          v-for="(item, index) in allProducts.filter((e)=>e.projectId === step4_form.getFieldValue('projectId'))">
                        <a-select-option v-if="null!=item" :key="item.id"
                                         :title="`${item['productName']} (#${item['productNo']})`">
                          {{ item['productName'] }} (#{{ item['productNo'] }})
                        </a-select-option>
                      </template>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Site" :colon="false">

                    <a-select
                        show-search
                        optionFilterProp="title"
                        style="width: 100%"
                        placeholder="Please select"
                        v-decorator="[
                            'clinicalPlaceId',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please select field type!'
                                },
                              ],
                            }
                          ]"
                    >
                      <a-select-option v-for="item in allClinicalPlace" :key="item.id"
                                       :title="item.clinicalPlaceName + ' (#'+item.clinicalPlaceNo+')'">
                        {{ item.clinicalPlaceName }} (#{{ item.clinicalPlaceNo }})
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Laboratory" :colon="false">

                    <a-select
                        show-search
                        optionFilterProp="title"
                        style="width: 100%"
                        placeholder="Please select"
                        v-decorator="[
                            'laboratoryId',
                            {
                              rules: [
                                {
                                  required: true, message: 'Please select field type!'
                                },
                              ],
                            }
                          ]"
                    >
                      <a-select-option v-for="item in allLaboratory" :key="item.id"
                                       :title="item.laboratoryName + ' (#'+item.laboratoryNo+')'">
                        {{ item.laboratoryName }} (#{{ item.laboratoryNo }})
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

              </a-row>

              <h5 class="mt-5">Range</h5>
              <a-row :gutter="[24]" type="flex">
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Patient # begin" :colon="false">
                    <div class="ant-input-group-wrapper">
                      <div class="ant-input-wrapper ant-input-group">
                        <span class="ant-input-group-addon">
                          <div>
                            {{
                              (() => {
                                const _id = step4_form.getFieldValue('projectId');
                                const _finded = allProjects.find((e)=>e.id === _id);
                                return _id && _finded ? _finded.projectNo : 'XX'
                              })()
                            }}-{{
                              (() => {
                                const _id = step4_form.getFieldValue('productId');
                                const _finded = allProducts.find((e)=>e.id === _id);
                                return _id && _finded ? _finded.productNo : 'XX'
                              })()
                            }}{{
                              (() => {
                                const _id = step4_form.getFieldValue('clinicalPlaceId');
                                const _finded = allClinicalPlace.find((e)=>e.id === _id);
                                return _id && _finded ? _finded.clinicalPlaceNo : 'XX'
                              })()
                            }}-
                          </div>
                        </span>

                        <a-input-number
                            class="ant-input"
                            placeholder="#"
                            :min="0"
                            v-decorator="[
                                  'patientNoStart',
                                  {
                                    rules: [
                                      {
                                        required: true, message: 'Please input Patient # begin!'
                                      },
                                    ],
                                  },
                                ]"
                        >
                        </a-input-number>
                      </div>
                    </div>
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Patient # end" :colon="false">
                    <div class="ant-input-group-wrapper">
                      <div class="ant-input-wrapper ant-input-group">
                        <span class="ant-input-group-addon">
                          <div>
                            {{
                              (() => {
                                const _id = step4_form.getFieldValue('projectId');
                                const _finded = allProjects.find((e)=>e.id === _id);
                                return _id && _finded ? _finded.projectNo : 'XX'
                              })()
                            }}-{{
                              (() => {
                                const _id = step4_form.getFieldValue('productId');
                                const _finded = allProducts.find((e)=>e.id === _id);
                                return _id && _finded ? _finded.productNo : 'XX'
                              })()
                            }}{{
                              (() => {
                                const _id = step4_form.getFieldValue('clinicalPlaceId');
                                const _finded = allClinicalPlace.find((e)=>e.id === _id);
                                return _id && _finded ? _finded.clinicalPlaceNo : 'XX'
                              })()
                            }}-
                          </div>
                        </span>

                        <a-input-number
                            class="ant-input"
                            placeholder="#"
                            :min="0"
                            v-decorator="[
                              'patientNoEnd',
                              {
                                rules: [
                                  {
                                    required: true, message: 'Please input Patient # end!'
                                  },
                                  {
                                    validator: (rule, value, callback) =>{
                                      const patientNoStart = step4_form.getFieldValue('patientNoStart');
                                      if(!patientNoStart && patientNoStart !==0){
                                        // step4_form.validateFieldsAndScroll(['patientRanges['+item+'].patientNoStart']);
                                        callback('Please enter begin first!');
                                        return;
                                      }

                                      callback();
                                    },
                                  },
                                  {
                                    validator: (rule, value, callback) =>{
                                      const patientNoStart = step4_form.getFieldValue('patientNoStart');

                                      if(value < patientNoStart){
                                        callback('Cannot be less than begin');
                                        return;
                                      }

                                      callback();
                                    },
                                  },
                                ],
                              },
                            ]"
                        >
                        </a-input-number>
                      </div>
                    </div>
                  </a-form-item>
                </a-col>
              </a-row>
            </div>


            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="24" class="text-right">
                <a-button type="primary" html-type="submit" class="px-25" :loading="submitBtnLoading">Add</a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </div>
    </a-loading>

  </div>

</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      pageLoadingState: 'loading',
      step4_form: this.$form.createForm(this, {name: 'step4_form'}),
      submitBtnLoading: false,
      allProjects: [],
      allProducts: [],
      allClinicalPlace: [],
      allLaboratory: [],
      projectIsSelected: false,
    }
  },
  async created() {

    this.pageLoadingState = 'loading';

    let result = await this.$axios.batchPost({
      urls: [
        {
          url: '/ctcProject/query/listProject',
        },
        {
          url: '/ctcProjectProduct/query/listAll',
        },
        {
          url: '/ctcClinicalPlace/query/listAll',
        },
        {
          url: '/ctcLaboratory/query/listAll',
        },
      ],
    });
    if (result.success) {
      this.allProjects = result.body[0].body;
      this.allProducts = result.body[1].body;
      this.allClinicalPlace = result.body[2].body;
      this.allLaboratory = result.body[3].body;
      this.pageLoadingState = 'success';
    } else {
      this.pageLoadingState = 'failed';
    }

  },
  methods: {
    onProjectSelect() {
      this.projectIsSelected = true;
      this.step4_form.resetFields('productId');
    },
    onProjectDeselect() {
      this.projectIsSelected = false;
    },
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();

      const values4 = await this.$fvs.validateFieldsAndScroll(this.step4_form);
      if (!values4) {
        this.$message.error("Patient incomplete data.");
        this.step = 4;
        return;
      }
      let params = {
        ...{
          ...values4,
        },
      };
      let params2 = JSON.parse(JSON.stringify(params));

      this.submitBtnLoading = true;

      let result = await this.$axios.post({
        url: '/ctcProjectCase/add',
        data: params2,
      });

      if (result.success) {
        const that = this;
        this.$message.success("Patient add success.").then(() => {
          that.submitBtnLoading = false;
          that.$router.replace("/basic-manage/patient-manage/patient-list");
        });

      } else {
        this.submitBtnLoading = false;
      }

    },
  },
}

</script>
