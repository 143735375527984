<!--
	This is the new user page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div class="mx-auto mt-50" style="max-width: 1024px;">

    <a-loading :state="pageLoadingState">
      <div class="mb-50">
        <!-- Step 2 -->
        <a-card :bordered="false" class="header-solid">
          <a-form
              :form="step1_form"
              @submit="handleSubmit"
          >
            <div>
              <h5 class="mt-5">Trial</h5>

              <a-row :gutter="[24]" type="flex">
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Trial" :colon="false">
                    <a-select
                        show-search
                        style="width: 100%"
                        optionFilterProp="title"
                        placeholder="Please select"
                        v-decorator="[
                      'projectId',
                      {
                            rules: [
                              {
                                required: true, message: 'Please select Trial!'
                              },
                            ],
                          },
                    ]"
                    >
                      <a-select-option v-for="item in allProjects" :key="item.id"
                                       :title="item.projectName + ' (#'+item.projectNo+')'">
                        {{ item.projectName }} (#{{ item.projectNo }})
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>

              <h5 class="mt-5">Device</h5>
              <a-row :gutter="[24]" type="flex">
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Device #" :colon="false">
                    <a-input
                        placeholder="eg. Street 111"
                        v-decorator="[
                          'productNo',
                          {
                            rules: [
                              {
                                required: true, message: 'Please input Device #!'
                              },
                            ],
                          },
                        ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Device Name" :colon="false">
                    <a-input
                        placeholder="eg. Street 111"
                        v-decorator="[
                          'productName',
                          {
                            rules: [
                              {
                                required: true, message: 'Please input Device Name!'
                              },
                            ],
                          },
                        ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="FDA Product Code" :colon="false">
                    <a-input
                        placeholder="eg. Street 111"
                        v-decorator="[
                          'fdaProductCode',
                        ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Panel" :colon="false">
                    <a-input
                        placeholder="eg. Street 111"
                        v-decorator="[
                          'panel',
                        ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Manufacturer" :colon="false">
                    <a-textarea
                        placeholder="Manufacturer info"
                        :auto-size="{ minRows: 3, maxRows: 6 }"
                        v-decorator="[
                      'productManufacturer',
                    ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="24" :sm="12">
                  <a-form-item class="mb-10" label="Introduce" :colon="false">
                    <a-textarea
                        placeholder="Introduce"
                        :auto-size="{ minRows: 3, maxRows: 6 }"
                        v-decorator="[
                      'productIntroduce',
                    ]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </div>

            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="24" class="text-right">
                <a-button type="primary" :loading="submitBtnLoading" html-type="submit" class="px-25">Add</a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </div>
    </a-loading>

  </div>

</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      pageLoadingState: 'loading' ,
      step1_form: this.$form.createForm(this, {name: 'step1_form'}),
      submitBtnLoading: false,
      allProjects: [],
    }
  },
  async created() {

    this.pageLoadingState = 'loading';


    let result = await this.$axios.batchPost({
      urls: [
        {
          url: '/ctcProject/query/listProject',
        },
      ],
    });
    if (result.success) {
      this.allProjects = result.body[0].body;
      this.pageLoadingState = 'success';
    } else {
      this.pageLoadingState = 'failed';
    }
  },
  methods: {
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();

      const values1 = await this.$fvs.validateFieldsAndScroll(this.step1_form);
      if (!values1) {
        this.$message.error("Devices incomplete data.");
        return;
      }
      let params = {
        ...{
          ...values1,
        },
      };
      let params2 = JSON.parse(JSON.stringify(params));

      this.submitBtnLoading = true;

      let result = await this.$axios.post({
        url: '/ctcProjectProduct/add',
        data: params2,
      });

      if (result.success) {
        const that = this;
        this.$message.success("Device add success.").then(() => {
          that.submitBtnLoading = false;
          that.$router.replace("/basic-manage/device-manage/device-list");
        });

      } else {
        this.submitBtnLoading = false;
      }

    },
  },
}

</script>
