<!--
	This is the Timeline page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div class="mx-auto mt-50" style="max-width: 1024px;">

    <a-loading :state="pageLoadingState">
      <div class="mb-50">
        <!-- Orders History Timeline Card -->
        <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{paddingTop: '12px',}">
          <template #title>
            <h6>#{{ resultView.projectCase.caseNo }}</h6>
          </template>
          <a-timeline>
            <a-timeline-item v-for="(item, index) in resultView.logs" :key="`log_${item.id}`"
                             :color="colorMap[item.type]">

              <a-tag :color="colorMap[item.type]">
                {{ item.type }}
              </a-tag>
              <span>by {{ item.createByUsername }} (#{{ item.createByUserNo }})</span>
<!--              <small>[ {{ item.createByRoleNameAndIdsStr }} ]</small>-->
              <small>[ log id: {{item.id}} ]</small>
              <p>
                {{ item.createTime }}
              </p>
            </a-timeline-item>
          </a-timeline>
        </a-card>
        <!-- / Orders History Timeline Card -->
      </div>
    </a-loading>
  </div>

</template>

<script>

export default {
  data() {
    return {
      pageLoadingState: 'loading',
      resultView: {
        projectCase: {},
        logs: [],
      },
      // green, danger, primary, warning, secondary, success, dark
      colorMap: {
        ADD: 'green',
        DELETE: 'red',
        CLINICAL_PLACE_ENTRY: 'blue',
        CLINICAL_PLACE_UPDATE: 'orange',
        LABORATORY_ENTRY: 'blue',
        LABORATORY_UPDATE: 'orange',
        CRO_ENTRY: 'blue',
        CRO_UPDATE: 'orange',
      },
    };
  },
  async created() {

    this.pageLoadingState = 'loading';

    let result = await this.$axios.batchPost({
      urls: [
        {
          url: '/ctcProjectCaseLog/query/listAll/byCaseId',
          data: {
            caseId: this.$route.params.id,
          }
        },
      ],
    });
    if (result.success) {
      this.resultView = result.body[0].body;
      this.pageLoadingState = 'success';
    } else {
      this.pageLoadingState = 'failed';
    }

  },
  methods: {},
};
</script>

<style lang="scss">
</style>
