<!-- 
	This is the new user page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>

    <a-loading :state="pageLoadingState">
      <a-row type="flex" :gutter="24">
        <a-col :span="24" :sm="24" :md="24" :lg="24" :xl="16" :xxl="18" class="mb-25 ">
          <a-card :bordered="false" style="overflow: hidden" class="header-solid leftFrame"
                  :tab-list="tabList"
                  :active-tab-key="activeTabKey"
                  @tabChange="key => onTabChange(key, 'activeTabKey')"
                  :headStyle="{paddingBottom: '0' }">
            <template v-for="(item) in tabList">
              <div v-show="item.key === activeTabKey">
                <iframe v-if="item.type==='office'" class="border-none wp-100 m-0 p-0"
                        style="height: calc(100vh - 255px);"
                        :src="`https://view.officeapps.live.com/op/view.aspx?src=${item.source.url}`">
                </iframe>
                <iframe v-else-if="item.type==='pdf'" class="border-none wp-100 m-0 p-0"
                        style="height: calc(100vh - 255px);"
                        :src="item.source.url">
                </iframe>
                <div v-else-if="item.type==='image'" class="border-none wp-100 m-0 p-0"
                     style="height: calc(100vh - 255px);">
                  <vueCropper
                      :canScale="true"
                      :img="item.source.url"
                  ></vueCropper>
                </div>
              </div>
            </template>
          </a-card>
        </a-col>
        <a-col :span="24" :sm="24" :md="24" :lg="24" :xl="8" :xxl="6" class="mb-25">
          <a-card :bordered="false" style="overflow: hidden" class="header-solid"
                  :bodyStyle="{paddingTop: 0, overflow: 'hidden' }"
                  :headStyle="{paddingBottom: '0' }">
            <b slot="title">Entry Area</b>

            <a-form
                :form="form"
                @submit="handleSubmit"
            >
              <a-row type="flex" :gutter="24">
                <a-col :span="24">
                  <a-form-item class="mb-20" label="Patient #" :colon="false">
                    <a-input placeholder="eg. 20-5566-001"
                             :disabled="true"
                             v-decorator="[
                        'caseNo',
                        {
                          rules: [
                            {
                              required: true, message: 'Please input Patient #!'
                            },
                          ],
                          initialValue: paramCaseNo,
                        },
                      ]"/>
                  </a-form-item>
                </a-col>
              </a-row>
              <h5 class="mt-5">Site</h5>
              <a-row type="flex" :gutter="24">
                <template v-for="(item, index) in EP_fieldDefinition">
                  <a-col :span="24" :key="`ep_col_${item.key}`" v-if="simpleInputFieldTypes.includes(item.type)">
                    <DynamicInputWidget :title="item.title" :keyName="`croClinicalPlaceFieldValue.${item.key}`" :required="item.required"
                                        :type="item.type"
                                        :values="item.values"/>
                  </a-col>
                </template>
                <a-col :span="24"/>
                <template v-for="(item, index) in EP_fieldDefinition">
                  <a-col :span="24" :key="`ep_col_${item.key}`" v-if="!simpleInputFieldTypes.includes(item.type)">
                    <FileDynamicInputWidget :form="form" :title="item.title" :keyName="`croClinicalPlaceFieldValue.${item.key}`"
                                            :required="item.required" :type="item.type" :values="item.values"/>
                  </a-col>
                </template>
              </a-row>
              <h5 class="mt-5">Laboratory</h5>
              <a-row type="flex" :gutter="24">
                <template v-for="(item, index) in LAB_fieldDefinition">
                  <a-col :span="24" :key="`lab_col_${item.key}`" v-if="simpleInputFieldTypes.includes(item.type)">
                    <DynamicInputWidget :title="item.title" :keyName="`croLaboratoryFieldValue.${item.key}`" :required="item.required"
                                        :type="item.type"
                                        :values="item.values"/>
                  </a-col>
                </template>
                <a-col :span="24"/>
                <template v-for="(item, index) in LAB_fieldDefinition">
                  <a-col :span="24" :key="`lab_col_${item.key}`" v-if="!simpleInputFieldTypes.includes(item.type)">
                    <FileDynamicInputWidget :form="form" :title="item.title" :keyName="`croLaboratoryFieldValue.${item.key}`"
                                            :required="item.required" :type="item.type" :values="item.values"/>
                  </a-col>
                </template>
              </a-row>

              <a-row type="flex" :gutter="[24]" class="mt-15">
                <a-col :span="24" class="text-right">
                  <a-button type="primary" html-type="submit" :loading="submitBtnLoading" class="px-25">SUBMIT
                  </a-button>
                </a-col>
              </a-row>
            </a-form>
            <!--          <div style="height: 2000px"></div>-->
          </a-card>
        </a-col>
      </a-row>
    </a-loading>
  </div>

</template>

<script>

import DynamicInputWidget from '@/components/Widgets/DynamicInputWidget.vue'
import FileDynamicInputWidget from '@/components/Widgets/FileDynamicInputWidget.vue'

import {VueCropper} from 'vue-cropper'

export default {
  components: {
    VueCropper,
    'DynamicInputWidget': DynamicInputWidget,
    'FileDynamicInputWidget': FileDynamicInputWidget,
  },
  data() {
    return {
      form: this.$form.createForm(this, {name: 'form'}),
      pageLoadingState: 'loading',
      paramCaseNo: '',
      tabList: [],
      submitBtnLoading: false,
      activeTabKey: 'tabKey_0',
      list: [
        {url: 'http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf'},
        {url: 'https://jfb-public-images.oss-cn-qingdao.aliyuncs.com/admin-upload/202111081034429231.png?x-oss-process=style/common'},
        {url: 'http://static.e56buy.com/XdgfsqR2INp7uFxTuLQtnMstYLY4K8rr.蛋糕缺少内容.docx', name: 'aaaa'},
        {
          url: 'http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf',
          name: 'aaaa',
          fid: 'aadadads',
          download: false,//是否展示下载按钮
        },
      ],
      projectCaseView: {
        projectCase: {},
        project: {},
      },
      simpleInputFieldTypes: ['int', 'float', 'date', 'time', 'dateTime', 'string', 'boolean', 'radio', 'checkBox'],
      officeFileFieldTypes: ['excelFile', 'wordFile', 'pptFile',],
      downloadFileFieldTypes: ['compressedFile', 'otherFile',],
      imageFileFieldTypes: ['imageFile',],
      pdfFileFieldTypes: ['pdfFile',],
      EP_fieldDefinition: [],
      LAB_fieldDefinition: [],
    }
  },
  async created() {
    console.log(this.$route);
    this.paramCaseNo = this.$route.params.id;


    this.pageLoadingState = 'loading';

    let result2 = await this.$axios.post({
      url: '/ctcProjectCase/query/loadByCaseNo4CRO_EP',
      data: {
        caseNo: this.$route.params.id,
      },
    });
    if (result2.success) {
      this.projectCaseView = result2.body;


      const clinicalPlaceFieldDefinition = JSON.parse(this.projectCaseView.project.clinicalPlaceFieldDefinition);
      const clinicalPlaceFieldValue = JSON.parse(this.projectCaseView.projectCase.clinicalPlaceFieldValue);

      let index = 1;
      for (const item1 of clinicalPlaceFieldDefinition) {
        if (!this.simpleInputFieldTypes.includes(item1["type"])) {
          for (const item2 of JSON.parse(clinicalPlaceFieldValue[item1["key"]])) {
            // const item2_json = JSON.parse(item2);
            let type = "download";
            if (this.officeFileFieldTypes.includes(item1["type"])) {
              type = "office";
            } else if (this.imageFileFieldTypes.includes(item1["type"])) {
              type = "image";
            } else if (this.pdfFileFieldTypes.includes(item1["type"])) {
              type = "pdf";
            }
            this.tabList.push({
              source: item2,
              tab: "[CP] File " + index,
              type: type,
            })
            index++
          }
        }
      }
      const laboratoryFieldDefinition = JSON.parse(this.projectCaseView.project.laboratoryFieldDefinition);
      const laboratoryFieldValue = JSON.parse(this.projectCaseView.projectCase.laboratoryFieldValue);

      index = 1;
      for (const item1 of laboratoryFieldDefinition) {
        if (!this.simpleInputFieldTypes.includes(item1["type"])) {
          for (const item2 of JSON.parse(laboratoryFieldValue[item1["key"]])) {
            // const item2_json = JSON.parse(item2);

            let type = "download";
            if (this.officeFileFieldTypes.includes(item1["type"])) {
              type = "office";
            } else if (this.imageFileFieldTypes.includes(item1["type"])) {
              type = "image";
            } else if (this.pdfFileFieldTypes.includes(item1["type"])) {
              type = "pdf";
            }
            this.tabList.push({
              source: item2,
              tab: "[LAB] File " + index,
              type: type,
            })
            index++
          }
        }
      }

      for (let i = 0; i < this.tabList.length; i++) {
        this.tabList[i].key = 'tabKey_' + i;
      }

      this.EP_fieldDefinition = JSON.parse(this.projectCaseView.project.croClinicalPlaceFieldDefinition);
      this.LAB_fieldDefinition = JSON.parse(this.projectCaseView.project.croLaboratoryFieldDefinition);


      this.pageLoadingState = 'success';
    } else {
      this.pageLoadingState = 'failed';
    }


  },
  methods: {
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();

      const values = await this.$fvs.validateFieldsAndScroll(this.form);
      if (!values) {
        return;
      }


      this.submitBtnLoading = true;

      let result = await this.$axios.post({
        url: '/ctcProjectCase/update/entry4CRO_EP',
        data: {
          id: this.projectCaseView.projectCase.id,
          croClinicalPlaceFieldValue: JSON.stringify(values.croClinicalPlaceFieldValue),
          croLaboratoryFieldValue: JSON.stringify(values.croLaboratoryFieldValue),
        },
      });

      if (result.success) {
        this.$message.success("entry successful");

        this.$router.replace("/CRO/CRO-entered-list-ep");
      } else {
        this.submitBtnLoading = false;
      }

    },
    moveStep(distance) {
      this.step += distance;
    },
    onTabChange(key, type) {
      console.log(key, type);
      this[type] = key;
    },
  },
}

</script>

<style lang="scss">
.leftFrame .ant-card-head-wrapper {
  min-height: auto !important;
}
</style>