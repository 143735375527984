var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":12}}),_c('a-col',{staticClass:"mb-24 text-right",attrs:{"span":12}})],1),_c('a-card',{staticClass:"header-solid mb-24",attrs:{"bordered":false,"bodyStyle":{padding: 0, paddingTop: '16px'}}},[_c('a-loading',{attrs:{"state":_vm.pageLoadingState}},[_c('div',{staticClass:"mx-25"},[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{attrs:{"span":24,"sm":12}}),_c('a-col',{staticClass:"text-right",attrs:{"span":24,"sm":12}},[_c('a-input-search',{staticStyle:{"max-width":"260px"},attrs:{"placeholder":"PATIENT #"},on:{"search":_vm.onSearch},model:{value:(_vm.pageData.unifiedQuery),callback:function ($$v) {_vm.$set(_vm.pageData, "unifiedQuery", $$v)},expression:"pageData.unifiedQuery"}})],1)],1)],1),_c('a-table',{staticClass:"mt-20 table-nowrap",attrs:{"scroll":{x:true},"loading":_vm.pageData.loading,"row-key":function (record) { return record.id; },"data-source":_vm.pageData.list,"pagination":_vm.pageData.pagination},on:{"change":_vm.handleTableChange}},[_c('a-table-column',{attrs:{"data-index":"caseNo","title":"PATIENT #","sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'caseNo' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{attrs:{"data-index":"projectId","title":"TRIAL","filteredValue":_vm.pageData.filters.projectId,"customRender":function (text, record, index){ return record.projectNo?((record.projectName) + " (#" + (record.projectNo) + ")"):null; },"filters":_vm.allProjects.map(function (e) {
                            return {
                              text: e.projectName + ' (#'+e.projectNo+')',
                              value: e.id,
                            }
                          })}}),_c('a-table-column',{attrs:{"data-index":"productId","title":"DEVICE","filters":_vm.allProducts.map(function (e) {
                            return {
                              text: ((e.productName) + " (#" + (e.productNo) + ") - " + (e.projectName) + " (#" + (e.projectNo) + ")"),
                              value: e.id,
                            }
                          }),"customRender":function (text, record, index){ return record.productNo?((record.productName) + " (#" + (record.productNo) + ")"):null; }}}),_c('a-table-column',{attrs:{"data-index":"laboratoryId","title":"LABORATORY","filters":_vm.allLaboratory.map(function (e) {
                            return {
                              text: ((e.laboratoryName) + " (#" + (e.laboratoryNo) + ")"),
                              value: e.id,
                            }
                          }),"customRender":function (text, record, index){ return record.laboratoryNo?((record.laboratoryName) + " (#" + (record.laboratoryNo) + ")"):null; }}}),_c('a-table-column',{attrs:{"data-index":"clinicalPlaceEnterUserId","title":"SITE ENTER USER","filters":_vm.cp_eps.map(function (e) {
                            return {
                              text: e.username + ' (#'+e.userNo+')',
                              value: e.id,
                            }
                          }),"customRender":function (text, record, index){ return record.clinicalPlaceEnterUserNo?((record.clinicalPlaceEnterUserName) + " (#" + (record.clinicalPlaceEnterUserNo) + ")"):null; }}}),_c('a-table-column',{attrs:{"data-index":"clinicalPlaceEnterTime","title":"SITE ENTER TIME","sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'clinicalPlaceEnterTime' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{attrs:{"data-index":"clinicalPlaceUpdateUserId","title":"SITE UPDATE USER","filters":_vm.cp_qcs.map(function (e) {
                            return {
                              text: e.username + ' (#'+e.userNo+')',
                              value: e.id,
                            }
                          }),"customRender":function (text, record, index){ return record.clinicalPlaceUpdateUserNo?((record.clinicalPlaceUpdateUserName) + " (#" + (record.clinicalPlaceUpdateUserNo) + ")"):null; }}}),_c('a-table-column',{attrs:{"data-index":"clinicalPlaceUpdateTime","title":"SITE UPDATE TIME","sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'clinicalPlaceUpdateTime' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{attrs:{"data-index":"laboratoryEnterUserId","title":"LABORATORY ENTER USER","filters":_vm.labEps.map(function (e) {
                            return {
                              text: e.username + ' (#'+e.userNo+')',
                              value: e.id,
                            }
                          }),"customRender":function (text, record, index){ return record.laboratoryEnterUserNo?((record.laboratoryEnterUserName) + " (#" + (record.laboratoryEnterUserNo) + ")"):null; }}}),_c('a-table-column',{attrs:{"data-index":"laboratoryEnterTime","title":"LABORATORY ENTER TIME","sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'laboratoryEnterTime' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{attrs:{"data-index":"laboratoryUpdateUserId","title":"LABORATORY UPDATE USER","filters":_vm.labEps.map(function (e) {
                            return {
                              text: e.username + ' (#'+e.userNo+')',
                              value: e.id,
                            }
                          }),"customRender":function (text, record, index){ return record.laboratoryUpdateUserNo?((record.laboratoryUpdateUserName) + " (#" + (record.laboratoryUpdateUserNo) + ")"):null; }}}),_c('a-table-column',{attrs:{"data-index":"laboratoryUpdateTime","title":"LABORATORY UPDATE TIME","sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'laboratoryUpdateTime' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{attrs:{"data-index":"croEnterUserId","title":"CRO ENTER USER","customRender":function (text, record, index){ return record.croEnterUserNo?((record.croEnterUserName) + " (#" + (record.croEnterUserNo) + ")"):null; }}}),_c('a-table-column',{attrs:{"data-index":"croEnterTime","title":"CRO ENTER TIME","sorter":"true","sortOrder":_vm.pageData.sorter.columnKey === 'croEnterTime' ? _vm.pageData.sorter.order : false}}),_c('a-table-column',{key:"action",attrs:{"title":"ACTION","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(content, record){return [_c('span',[_c('router-link',{attrs:{"to":'/CRO/CRO-entry-ep/'+record.caseNo,"target":"_blank"}},[_vm._v(" Entry ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }