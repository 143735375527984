<!--
	This is the default layout, used in sign-in and sign-up pages.
 -->

<template>
  <router-view />
</template>

<script>


	export default ({
		components: {
		},
		data() {
			return {
			}
		},
		computed: {
		},
	})

</script>
