var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form-item',{staticClass:"mb-10",attrs:{"label":_vm.title,"colon":false}},[(_vm.type === 'int')?_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.keyName,
          {
            rules: [
              {
                required: _vm.required,
                message: ("Please input " + _vm.title + "."),
              } ],
          } ]),expression:"[\n          keyName,\n          {\n            rules: [\n              {\n                required: required,\n                message: `Please input ${title}.`,\n              },\n            ],\n          },\n        ]"}],staticClass:"ant-input",staticStyle:{"width":"100%"},attrs:{"placeholder":("Please input " + _vm.title),"precision":0}}):(_vm.type === 'float')?_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.keyName,
          {
            rules: [
              {
                required: _vm.required,
                message: ("Please input " + _vm.title + "."),
              } ],
          } ]),expression:"[\n          keyName,\n          {\n            rules: [\n              {\n                required: required,\n                message: `Please input ${title}.`,\n              },\n            ],\n          },\n        ]"}],staticClass:"ant-input",staticStyle:{"width":"100%"},attrs:{"placeholder":("Please input " + _vm.title)}}):(_vm.type === 'date')?_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.keyName,
          {
            rules: [
              {
                required: _vm.required,
                message: ("Please select " + _vm.title + "."),
              } ],
          } ]),expression:"[\n          keyName,\n          {\n            rules: [\n              {\n                required: required,\n                message: `Please select ${title}.`,\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"valueFormat":"YYYY-MM-DD","placeholder":("Please select " + _vm.title)}}):(_vm.type === 'time')?_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.keyName,
          {
            rules: [
              {
                required: _vm.required,
                message: ("Please select " + _vm.title + "."),
              } ],
          } ]),expression:"[\n          keyName,\n          {\n            rules: [\n              {\n                required: required,\n                message: `Please select ${title}.`,\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"valueFormat":"HH:mm:ss","placeholder":("Please select " + _vm.title),"default-open-value":_vm.moment(),"inputReadOnly":true}}):(_vm.type === 'dateTime')?_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.keyName,
          {
            rules: [
              {
                required: _vm.required,
                message: ("Please select " + _vm.title + "."),
              } ],
          } ]),expression:"[\n          keyName,\n          {\n            rules: [\n              {\n                required: required,\n                message: `Please select ${title}.`,\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"show-time":"","valueFormat":"YYYY-MM-DD HH:mm:ss","placeholder":("Please select " + _vm.title)}}):(_vm.type === 'string')?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.keyName,
          {
            rules: [
              {
                required: _vm.required,
                message: ("Please input " + _vm.title + "."),
              } ],
          } ]),expression:"[\n          keyName,\n          {\n            rules: [\n              {\n                required: required,\n                message: `Please input ${title}.`,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":("Please input " + _vm.title)}}):(_vm.type === 'boolean')?_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.keyName,
          {
            rules: [
              {
                required: _vm.required,
                message: ("Please select " + _vm.title + "."),
              } ],
          } ]),expression:"[\n          keyName,\n          {\n            rules: [\n              {\n                required: required,\n                message: `Please select ${title}.`,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":("Please select " + _vm.title)}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(" Yes ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v(" No ")])],1):(_vm.type === 'radio')?_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.keyName,
          {
            rules: [
              {
                required: _vm.required,
                message: ("Please select " + _vm.title + "."),
              } ],
          } ]),expression:"[\n          keyName,\n          {\n            rules: [\n              {\n                required: required,\n                message: `Please select ${title}.`,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":("Please select " + _vm.title)}},_vm._l((_vm.values),function(item,index){return _c('a-radio',{key:(_vm.keyName + "_" + index),attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1):(_vm.type === 'checkBox')?_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.keyName,
          {
            rules: [
              {
                required: _vm.required,
                message: ("Please select " + _vm.title + "."),
              } ],
          } ]),expression:"[\n          keyName,\n          {\n            rules: [\n              {\n                required: required,\n                message: `Please select ${title}.`,\n              },\n            ],\n          },\n        ]"}],attrs:{"placeholder":("Please select " + _vm.title)}},_vm._l((_vm.values),function(item,index){return _c('a-checkbox',{key:(_vm.keyName + "_" + index),attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }