<!--
	This is the DataTables page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->
<template>

  <div>
    <a-row type="flex" :gutter="24">
      <a-col :span="12" class="mb-24">
        <!--        <a-button @click="csvExport(csvData)" class="ml-15">-->
        <!--          <i class="ni ni-archive-2 mr-5"></i> EXPORT CSV-->
        <!--        </a-button>-->

      </a-col>
      <a-col :span="12" class="mb-24 text-right">
        <router-link to="/settings/user-setting/user-setting-add" class="ant-btn ant-btn-primary">NEW USER</router-link>
      </a-col>
    </a-row>
    <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{padding: 0, paddingTop: '16px'}">
      <!--      <template #title>-->
      <!--        <h5 class="font-semibold m-0">Project List</h5>-->
      <!--      </template>-->

      <a-loading :state="pageLoadingState">
        <div class="mx-25">
          <a-row type="flex" :gutter="24">
            <a-col :span="24" :sm="12">
              <!--            <a-select v-model="pageData.pagination.pageSize"-->
              <!--                      style="width: 70px">-->
              <!--              <a-select-option :value="15">15</a-select-option>-->
              <!--              <a-select-option :value="30">30</a-select-option>-->
              <!--              <a-select-option :value="50">50</a-select-option>-->
              <!--              <a-select-option :value="75">75</a-select-option>-->
              <!--              <a-select-option :value="100">100</a-select-option>-->
              <!--            </a-select>-->
              <!--            <label class="ml-10">entries per page</label>-->
            </a-col>
            <a-col :span="24" :sm="12" class="text-right">
              <a-input-search placeholder="USER #, USERNAME, NICKNAME" style="max-width: 260px;"
                              v-model="pageData.unifiedQuery"
                              @search="onSearch"/>
            </a-col>
          </a-row>
        </div>
        <a-table class="mt-20 table-nowrap"
                 :scroll="{x:true}"
                 :loading="pageData.loading"
                 :row-key="record => record.id"
                 :data-source="pageData.list"
                 @change="handleTableChange"
                 :pagination="pageData.pagination"
        >
          <a-table-column data-index="userNo" title="USER #" fixed="left" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'userNo' ? pageData.sorter.order : false"/>
          <a-table-column data-index="username" title="USERNAME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'username' ? pageData.sorter.order : false"/>
          <a-table-column data-index="roleNameAndIdsStr" title="ROLES" :filters="this.roles.map((e) => {
                        return {
                          text: e.name + ' (#'+e.id+')',
                          value: e.id,
                        }
                      })"/>
          <a-table-column data-index="nickname" title="NICKNAME"/>
          <a-table-column data-index="phone" title="PHONE"/>
          <a-table-column data-index="email" title="EMAIL"/>
          <a-table-column data-index="loginIp" title="LAST LOGIN IP" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'loginIp' ? pageData.sorter.order : false"/>
          <a-table-column data-index="loginTime" title="LAST LOGIN TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'loginTime' ? pageData.sorter.order : false"/>
          <a-table-column data-index="createBy" title="CREATE BY"
                          :customRender="(text, record, index)=>record.createByUsername + `(#${record.createByUserNo})`" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'createBy' ? pageData.sorter.order : false"/>
          <a-table-column data-index="createTime" title="CREATE TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'createTime' ? pageData.sorter.order : false"/>
          <a-table-column data-index="updateBy" title="UPDATE BY"
                          :customRender="(text, record, index)=>record.updateByUsername ? (record.updateByUsername + `(#${record.updateByUserNo})`) : null" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'updateBy' ? pageData.sorter.order : false"/>
          <a-table-column data-index="updateTime" title="UPDATE TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'updateTime' ? pageData.sorter.order : false"/>
          <a-table-column key="action" title="ACTION" fixed="right">
            <template v-slot="content, record">
              <span v-if="record.id != currentUser.id">
                <router-link :to="'/settings/user-setting/user-setting-update/'+record.id" target="_blank">
                  Update
                </router-link>
                <a-divider type="vertical"/>
                <a href="javascript:;" @click="clickDelete(record)">Delete</a>
              </span>
              <span v-else>
                Current user
              </span>
            </template>
          </a-table-column>
        </a-table>
      </a-loading>
    </a-card>
    <!-- / Searchable Datatable card -->

  </div>

</template>

<script>


export default {
  components: {},
  data() {
    return {
      pageData: {
        loading: true,
        pagination: {
          showSizeChanger: true,
          pageSizeOptions: ['15', '30', '50', '75', '100'],
          defaultPageSize: 15,
          current: 1,
          pageSize: 15,
          total: 0,
          pages: 0,
          showQuickJumper: true,
          showTotal: (total, range) => {
            // console.log(range);
            return '' + range[0] + ' to ' + range[1] + ', total ' + total;
          },
        },
        list: [],
        unifiedQuery: '',
        filters: {},
        sorter: {},
        params: {},
      },
      currentUser: {},
      pageLoadingState: 'loading',
      roles: [],
    }
  },
  async created() {
    this.currentUser = JSON.parse(localStorage.getItem("HMC_USER"));
    this.pageLoadingState = 'loading';

    let result = await this.$axios.post({
      url: '/ctcUserRole/query/listAll',
    });

    if (result.success) {
      this.roles = result.body;
      this.pageLoadingState = 'success';
      await this.reloadTableData(true);
    } else {
      this.pageLoadingState = 'failed';
    }
  },
  methods: {

    async reloadTableData(backFirstPage) {
      if (backFirstPage) {
        this.pageData.pagination.current = 1;
      }
      await this.handleTableChange(this.pageData.pagination, this.pageData.filters, this.pageData.sorter);
    },

    async handleTableChange(pagination, filters, sorter) {

      // const pageParam = buildPageParam(filters, pagination, sorter);


      this.pageData.loading = true;
      const pageParam = this.$buildPageParam(pagination, filters, sorter);
      let result = await this.$axios.post({
        url: '/ctcUser/query/pageUser',
        data: {
          ...pageParam,
          orders: [
            ...pageParam.orders,
            {
              column: "id",
              asc: false,
            }
          ],
          params: this.pageData.params,
          paramsSelectType: 'OR',
        },
      });

      this.pageData = {
        ...this.pageData,
        filters,
        sorter,
      };
      if (result.success) {
        const resultBody = result.body;
        this.pageData = {
          ...this.pageData,
          list: resultBody.list,
          pagination: {
            ...this.pageData.pagination,
            ...resultBody.pagination,
          },
        }
      } else {
        this.pageData = {
          ...this.pageData,
          list: [],
        }
      }

      this.pageData.loading = false;

      console.log(this.pageData.sorter)


      // console.log('pageParam', pageParam);
      // console.log('pagination', pagination);
      // console.log('filters', filters);
      // console.log('sorter', sorter);
    },

    // Event handler for second table's search.
    async onSearch() {
      if (this.pageData.unifiedQuery) {

        this.pageData.params = {
          "split:userNo": this.pageData.unifiedQuery,
          "split:username": this.pageData.unifiedQuery,
          "split:nickname": this.pageData.unifiedQuery,
        };
      } else {
        this.pageData.params = {};
      }
      await this.reloadTableData(true);
    },

    clickDelete(record) {

      const that = this;

      this.$confirm({
        title: 'Are you sure you want to delete ' + record.username + ' (#' + record.userNo + ') ?',
        content: 'All information related to this user will be deleted, this operation cannot be undone!',
        okText: 'Sure',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let result = await that.$axios.post({
              url: '/ctcUser/delete/deleteUser',
              data: {
                id: record.id,
              },
            });
            if (result.success) {
              resolve();
              that.$message.success("successfully deleted");
              that.reloadTableData();
            } else {
              reject();
            }
          });
        },
        onCancel() {
        },
      });

    },

  }
  ,
}

</script>

<style lang="scss">
</style>
