var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto mt-50",staticStyle:{"max-width":"1024px"}},[_c('a-loading',{attrs:{"state":_vm.pageLoadingState}},[_c('div',{staticClass:"mb-50"},[_c('a-card',{staticClass:"header-solid",attrs:{"bordered":false}},[_c('a-form',{attrs:{"form":_vm.step1_form},on:{"submit":_vm.handleSubmit}},[_c('div',[_c('h5',{staticClass:"mt-5"},[_vm._v("Client")]),_c('a-row',{attrs:{"gutter":[24],"type":"flex"}},[_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Client #","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'clientNo',
                          {
                            rules: [
                              {
                                required: true, message: 'Please input Client #!'
                              } ],
                            initialValue: _vm.updateOriginal.clientNo,
                          } ]),expression:"[\n                          'clientNo',\n                          {\n                            rules: [\n                              {\n                                required: true, message: 'Please input Client #!'\n                              },\n                            ],\n                            initialValue: updateOriginal.clientNo,\n                          },\n                        ]"}],attrs:{"placeholder":"eg. Street 111"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Company Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'companyName',
                          {
                            initialValue: _vm.updateOriginal.companyName,
                          } ]),expression:"[\n                          'companyName',\n                          {\n                            initialValue: updateOriginal.companyName,\n                          },\n                        ]"}],attrs:{"placeholder":"eg. Street 111"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Phone Number","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'phoneNumber',
                          {
                            initialValue: _vm.updateOriginal.phoneNumber,
                          } ]),expression:"[\n                          'phoneNumber',\n                          {\n                            initialValue: updateOriginal.phoneNumber,\n                          },\n                        ]"}],attrs:{"placeholder":"eg. Street 111"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"E-mail","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'email',
                          {
                            initialValue: _vm.updateOriginal.email,
                          } ]),expression:"[\n                          'email',\n                          {\n                            initialValue: updateOriginal.email,\n                          },\n                        ]"}],attrs:{"placeholder":"eg. Street 111"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Remark","colon":false}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'remark',
                          {
                            initialValue: _vm.updateOriginal.remark,
                          } ]),expression:"[\n                          'remark',\n                          {\n                            initialValue: updateOriginal.remark,\n                          },\n                        ]"}],attrs:{"placeholder":"Remark","auto-size":{ minRows: 3, maxRows: 6 }}})],1)],1)],1)],1),_c('a-row',{staticClass:"mt-15",attrs:{"type":"flex","gutter":[24]}},[_c('a-col',{staticClass:"text-right",attrs:{"span":24}},[_c('a-button',{staticClass:"px-25",attrs:{"type":"primary","loading":_vm.submitBtnLoading,"html-type":"submit"}},[_vm._v("Update")])],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }