var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form-item',{staticClass:"mb-10",attrs:{"label":_vm.title,"colon":false}},[_c('div',{staticClass:"h-5"}),_c('a-upload-dragger',{attrs:{"accept":_vm.fileTypeAcceptMap[_vm.type],"name":"file","multiple":true,"withCredentials":true,"action":"/api/ctcProject/upload/file"},on:{"change":function (info){ return _vm.handleUploadChange(info); }}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" Click or drag file to this area to upload ")]),_c('p',{staticClass:"ant-upload-hint"},[_vm._v(" Support for a single or bulk upload. ")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.keyName,
          {
            rules: [
              {
                required: _vm.required,
                message: ("Please upload " + _vm.title + "."),
              } ],
          } ]),expression:"[\n          keyName,\n          {\n            rules: [\n              {\n                required: required,\n                message: `Please upload ${title}.`,\n              },\n            ],\n          },\n        ]"}],attrs:{"type":"hidden","placeholder":("Please upload " + _vm.title)}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }