<!--
	This is the DataTables page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->
<template>

  <div>
<!--    <a-row type="flex" :gutter="24">-->
<!--      <a-col :span="12" class="mb-24">-->
<!--        &lt;!&ndash;        <a-button @click="csvExport(csvData)" class="ml-15">&ndash;&gt;-->
<!--        &lt;!&ndash;          <i class="ni ni-archive-2 mr-5"></i> EXPORT CSV&ndash;&gt;-->
<!--        &lt;!&ndash;        </a-button>&ndash;&gt;-->

<!--      </a-col>-->
<!--      <a-col :span="12" class="mb-24 text-right">-->
<!--        <a-button type="primary"-->
<!--                  @click="()=>{this.$router.push('/basic-manage/laboratory-manage/add-laboratory')}">NEW PATIENT-->
<!--        </a-button>-->
<!--      </a-col>-->
<!--    </a-row>-->
    <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{padding: 0, paddingTop: '16px'}">
      <!--      <template #title>-->
      <!--        <h5 class="font-semibold m-0">Trial List</h5>-->
      <!--      </template>-->

      <a-loading :state="pageLoadingState">
        <div class="mx-25">
          <a-row type="flex" :gutter="24">
            <a-col :span="24" :sm="12">
              <!--            <a-select v-model="pageData.pagination.pageSize"-->
              <!--                      style="width: 70px">-->
              <!--              <a-select-option :value="15">15</a-select-option>-->
              <!--              <a-select-option :value="30">30</a-select-option>-->
              <!--              <a-select-option :value="50">50</a-select-option>-->
              <!--              <a-select-option :value="75">75</a-select-option>-->
              <!--              <a-select-option :value="100">100</a-select-option>-->
              <!--            </a-select>-->
              <!--            <label class="ml-10">entries per page</label>-->
            </a-col>
            <a-col :span="24" :sm="12" class="text-right">
              <a-input-search placeholder="PATIENT #" style="max-width: 260px;"
                              v-model="pageData.unifiedQuery"
                              @search="onSearch"/>
            </a-col>
          </a-row>
        </div>
        <a-table class="mt-20 table-nowrap"
                 :scroll="{x:true}"
                 :loading="pageData.loading"
                 :row-key="record => record.id"
                 :data-source="pageData.list"
                 @change="handleTableChange"
                 :pagination="pageData.pagination"
        >
          <a-table-column data-index="caseNo" title="PATIENT #" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'caseNo' ? pageData.sorter.order : false"/>
          <a-table-column data-index="projectId" title="TRIAL"
                          :filteredValue="pageData.filters.projectId"
                          :customRender="(text, record, index)=>record.projectNo?`${record.projectName} (#${record.projectNo})`:null"
                          :filters="allProjects.map((e) => {
                            return {
                              text: e.projectName + ' (#'+e.projectNo+')',
                              value: e.id,
                            }
                          })"/>
          <a-table-column data-index="productId" title="DEVICE"
                          :filters="allProducts.map((e) => {
                            return {
                              text: `${e.productName} (#${e.productNo}) - ${e.projectName} (#${e.projectNo})`,
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.productNo?`${record.productName} (#${record.productNo})`:null"/>
          <a-table-column data-index="clinicalPlaceId" title="SITE"
                          :filters="allClinicalPlace.map((e) => {
                            return {
                              text: `${e.clinicalPlaceName} (#${e.clinicalPlaceNo})`,
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.clinicalPlaceNo?`${record.clinicalPlaceName} (#${record.clinicalPlaceNo})`:null"/>
          <a-table-column data-index="laboratoryId" title="LABORATORY"
                          :filters="allLaboratory.map((e) => {
                            return {
                              text: `${e.laboratoryName} (#${e.laboratoryNo})`,
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.laboratoryNo?`${record.laboratoryName} (#${record.laboratoryNo})`:null"/>

          <a-table-column data-index="clinicalPlaceEnterUserId" title="ENTER USER"
                          :filters="cp_eps.map((e) => {
                            return {
                              text: e.username + ' (#'+e.userNo+')',
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.clinicalPlaceEnterUserNo?`${record.clinicalPlaceEnterUserName} (#${record.clinicalPlaceEnterUserNo})`:null"/>
          <a-table-column data-index="clinicalPlaceEnterTime" title="ENTER TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'clinicalPlaceEnterTime' ? pageData.sorter.order : false"/>
          <a-table-column data-index="clinicalPlaceUpdateUserId" title="UPDATE USER"
                          :filters="cp_qcs.map((e) => {
                            return {
                              text: e.username + ' (#'+e.userNo+')',
                              value: e.id,
                            }
                          })"
                          :customRender="(text, record, index)=>record.clinicalPlaceUpdateUserNo?`${record.clinicalPlaceUpdateUserName} (#${record.clinicalPlaceUpdateUserNo})`:null"/>
          <a-table-column data-index="clinicalPlaceUpdateTime" title="UPDATE TIME" sorter="true"
                          :sortOrder="pageData.sorter.columnKey === 'clinicalPlaceUpdateTime' ? pageData.sorter.order : false"/>


<!--          <a-table-column data-index="createBy" title="CREATE BY"-->
<!--                          :customRender="(text, record, index)=>record.createByUsername" sorter="true"-->
<!--                          :sortOrder="pageData.sorter.columnKey === 'createBy' ? pageData.sorter.order : false"/>-->
<!--          <a-table-column data-index="createTime" title="CREATE TIME" sorter="true"-->
<!--                          :sortOrder="pageData.sorter.columnKey === 'createTime' ? pageData.sorter.order : false"/>-->
<!--          <a-table-column data-index="updateBy" title="UPDATE BY"-->
<!--                          :customRender="(text, record, index)=>record.updateByUsername" sorter="true"-->
<!--                          :sortOrder="pageData.sorter.columnKey === 'updateBy' ? pageData.sorter.order : false"/>-->
<!--          <a-table-column data-index="updateTime" title="UPDATE TIME" sorter="true"-->
<!--                          :sortOrder="pageData.sorter.columnKey === 'updateTime' ? pageData.sorter.order : false"/>-->
          <a-table-column key="action" title="ACTION" fixed="right">
            <template v-slot="content, record">
              <span>
                <router-link :to="'/coming-soon?r='+record.id"  target="_blank">
                  Detail
                </router-link>
                <a-divider type="vertical"/>
                <router-link :to="'/coming-soon?r='+record.id" target="_blank">
                  Update
                </router-link>
<!--                <a-divider type="vertical"/>-->
<!--                <a href="javascript:;" @click="clickDelete(record)">Delete</a>-->
              </span>
            </template>
          </a-table-column>
        </a-table>
      </a-loading>
    </a-card>
    <!-- / Searchable Datatable card -->

  </div>

</template>

<script>


export default {
  components: {},
  data() {
    return {
      pageData: {
        loading: true,
        pagination: {
          showSizeChanger: true,
          pageSizeOptions: ['15', '30', '50', '75', '100'],
          defaultPageSize: 15,
          current: 1,
          pageSize: 15,
          total: 0,
          pages: 0,
          showQuickJumper: true,
          showTotal: (total, range) => {
            // console.log(range);
            return '' + range[0] + ' to ' + range[1] + ', total ' + total;
          },
        },
        list: [],
        unifiedQuery: '',
        filters: {
          projectId: [],
        },
        sorter: {},
        params: {},
      },
      pageLoadingState: 'loading',
      labEps: [],
      cp_eps: [],
      cp_qcs: [],
      allProjects: [],
      allProducts: [],
      allClinicalPlace: [],
      allLaboratory: [],
    }
  },
  async created() {
    if (!isNaN(this.$route.query.project)) {
      this.pageData.filters.projectId.push(parseInt(this.$route.query.project));
    }

    this.pageLoadingState = 'loading';

    let result = await this.$axios.batchPost({
      urls: [
        {
          url: '/ctcProject/query/listProject4CP_QC',
        },
        {
          url: '/ctcProjectProduct/query/listAll4CP_QC',
        },
        {
          url: '/ctcClinicalPlace/query/listAll4CP_QC',
        },
        {
          url: '/ctcLaboratory/query/listAll4CP_QC',
        },
      ],
    });
    if (result.success) {
      this.allProjects = result.body[0].body ?? [];
      this.allProducts = result.body[1].body ?? [];
      this.allClinicalPlace = result.body[2].body ?? [];
      this.allLaboratory = result.body[3].body ?? [];
      this.pageLoadingState = 'success';
      await this.reloadTableData(true);
    } else {
      this.pageLoadingState = 'failed';
    }

  },
  methods: {

    async reloadTableData(backFirstPage) {
      if (backFirstPage) {
        this.pageData.pagination.current = 1;
      }
      await this.handleTableChange(this.pageData.pagination, this.pageData.filters, this.pageData.sorter);
    },

    async handleTableChange(pagination, filters, sorter) {

      // const pageParam = buildPageParam(filters, pagination, sorter);


      this.pageData.loading = true;
      const pageParam = this.$buildPageParam(pagination, filters, sorter);
      let result = await this.$axios.post({
        url: '/ctcProjectCase/query/page4CP_QC',
        data: {
          ...pageParam,
          orders: [
            ...pageParam.orders,
            {
              column: "id",
              asc: false,
            }
          ],
          params: this.pageData.params,
          paramsSelectType: 'OR',
        },
      });

      this.pageData = {
        ...this.pageData,
        filters,
        sorter,
      };
      if (result.success) {
        const resultBody = result.body;
        this.pageData = {
          ...this.pageData,
          list: resultBody.list,
          pagination: {
            ...this.pageData.pagination,
            ...resultBody.pagination,
          }
        }
      } else {
        this.pageData = {
          ...this.pageData,
          list: [],
        }
      }

      this.pageData.loading = false;


      // console.log('pageParam', pageParam);
      // console.log('pagination', pagination);
      // console.log('filters', filters);
      // console.log('sorter', sorter);
    },

    // Event handler for second table's search.
    async onSearch() {
      if (this.pageData.unifiedQuery) {

        this.pageData.params = {
          "split:caseNo": this.pageData.unifiedQuery,
        };
      } else {
        this.pageData.params = {};
      }
      await this.reloadTableData(true);
    },

    clickDelete(record) {

      const that = this;

      this.$confirm({
        title: 'Are you sure you want to delete ' + record.laboratoryName + ' (#' + record.laboratoryNo + ') ?',
        content: 'All related data will be deleted, this operation cannot be undone!',
        okText: 'Sure',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let result = await that.$axios.post({
              url: '/ctcLaboratory/delete/byId',
              data: {
                id: record.id,
              },
            });
            if (result.success) {
              resolve();
              that.$message.success("successfully deleted");
              that.reloadTableData();
            } else {
              reject();
            }
          });
        },
        onCancel() {
        },
      });

    },

  }
  ,
}

</script>

<style lang="scss">
</style>
