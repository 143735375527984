<!-- 
	This is the new user page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div class="mx-auto mt-50" style="max-width: 1024px;">

    <div class="mb-50 mx-auto" style="max-width: 512px;">

      <a-steps progress-dot v-model="step">
        <a-step :disabled="true" title="Patient #"/>
        <a-step :disabled="true" title="Entry"/>
      </a-steps>

    </div>

    <div class="mb-50">
      <!-- Step 1 -->
      <a-card v-show="step === 0" :bordered="false" class="header-solid" :bodyStyle="{paddingTop: 0 }"
              :headStyle="{paddingBottom: '0' }">
        <a-form
            @submit="nextStep"
            :form="step0_form"
        >
          <a-row class="mt-25 flex-justify-content-center" type="flex" :gutter="[24]">
            <a-col :span="24" :sm="12">
              <a-form-item class="mb-20" label="Patient #" :colon="false">
                <a-input placeholder="eg. 20-5566-001"
                         v-decorator="[
                      'caseNo',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input Patient #!'
                          },
                        ],
                      },
                    ]"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="[24]" class="mt-15">
            <a-col :span="24" class="text-right">
              <a-button type="primary" html-type="submit" :loading="nextBtnLoading" class="px-25">NEXT</a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-card>
      <!-- Step 2 -->
      <a-card v-show="step === 1" :bordered="false" class="header-solid" :bodyStyle="{paddingTop: 0 }"
              :headStyle="{paddingBottom: '0' }">
        <a-form
            :form="step1_form"
            @submit="handleSubmit"
        >
          <a-row :class="`mb-50 mt-25 ${fieldDefinition.length<=1 ? 'flex-justify-content-center' : ''}`" type="flex"
                 :gutter="[24]">
            <template v-for="(item, index) in fieldDefinition">
              <a-col :span="24" :sm="12" :key="`col_${item.key}`" v-if="simpleInputFieldTypes.includes(item.type)">
                <DynamicInputWidget :title="item.title" :keyName="item.key" :required="item.required" :type="item.type"
                                    :values="item.values"/>
              </a-col>
            </template>
            <a-col :span="24"/>
            <template v-for="(item, index) in fieldDefinition">
              <a-col :span="24" :sm="12" :key="`col_${item.key}`" v-if="!simpleInputFieldTypes.includes(item.type)">
                <FileDynamicInputWidget :form="step1_form" :title="item.title" :keyName="item.key"
                                        :required="item.required" :type="item.type" :values="item.values"/>
              </a-col>
            </template>
          </a-row>
          <a-row type="flex" :gutter="[24]" class="mt-15">
            <a-col :span="12">
              <a-button @click="prevStep()" class="px-25">PREV</a-button>
            </a-col>
            <a-col :span="12" class="text-right">
              <a-button type="primary" html-type="submit" :loading="submitBtnLoading" class="px-25">SUBMIT</a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-card>
    </div>

  </div>

</template>

<script>

import DynamicInputWidget from '@/components/Widgets/DynamicInputWidget.vue'
import FileDynamicInputWidget from '@/components/Widgets/FileDynamicInputWidget.vue'

export default {
  components: {
    'DynamicInputWidget': DynamicInputWidget,
    'FileDynamicInputWidget': FileDynamicInputWidget,
  },
  data() {
    return {
      step0_form: this.$form.createForm(this, {name: 'step0_form'}),
      step1_form: this.$form.createForm(this, {name: 'step1_form'}),
      step: 0,
      projectView: {
        project: {},
      },
      nextBtnLoading: false,
      submitBtnLoading: false,
      fieldDefinition: [],
      simpleInputFieldTypes: ['int', 'float', 'date', 'time', 'dateTime', 'string', 'boolean', 'radio', 'checkBox'],
    }
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    // this.form = this.$form.createForm(this, {name: 'user'});
  },
  methods: {
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();

      const values = await this.$fvs.validateFieldsAndScroll(this.step1_form);
      if (!values) {
        return;
      }
      console.log(values);

      this.submitBtnLoading = true;

      let result = await this.$axios.post({
        url: '/ctcProjectCase/update/entry4CP_EP',
        data: {
          id: this.projectView.caseId,
          clinicalPlaceFieldValue: JSON.stringify(values),
        },
      });

      if (result.success) {
        const that = this;
        this.$message.success("entry successful");

        this.$confirm({
          title: 'Do you want to continue entry?',
          okText: 'Continue',
          cancelText: 'View entered list',
          onOk() {
            that.step0_form.resetFields();
            that.step1_form.resetFields();
            that.step = 0;
            that.submitBtnLoading = false;
            that.projectView = {
              project: {},
            };
            that.fieldDefinition = [];
          },
          onCancel() {
            that.$router.replace("/site/cp-entered-list-ep");
          },
        });
      } else {
        this.submitBtnLoading = false;
      }

    },
    async nextStep(e) {
      e.preventDefault();
      const values = await this.$fvs.validateFieldsAndScroll(this.step0_form);
      if (!values) {
        return;
      }

      this.nextBtnLoading = true;

      let result = await this.$axios.post({
        url: '/ctcProject/query/loadByCaseNo4CP_EP',
        data: values,
      });

      if (result.success) {
        this.projectView = result.body;
        this.fieldDefinition = JSON.parse(this.projectView.project.clinicalPlaceFieldDefinition);
        // console.log(this.fieldDefinition);
        this.step++;
      }

      this.nextBtnLoading = false;
    },
    async prevStep() {
      this.step--;
    }
  },
}

</script>

<style lang="scss">
</style>