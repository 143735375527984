<!--
	This is the new user page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>
    <div class="mb-24 mx-auto" style="max-width: 800px">
      <a-card :bordered="false" class="header-solid">
        <a-loading :state="pageLoadingState">
          <a-form
              :form="form"
              @submit="handleSubmit"
          >
            <h5 class="mt-5">Login information</h5>
            <a-row :gutter="[24]" type="flex">
              <a-col :span="24" :sm="12">
                <a-form-item :required="true" class="mb-10" label="User #" :colon="false">
                  <a-input
                      v-decorator="[
                      'userNo',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input user no!'
                          },
                        ],
                        initialValue: userInfo.user.userNo,
                      },
                    ]"
                      placeholder="eg. Michael"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item :required="true" class="mb-10" label="Username" :colon="false">
                  <a-input

                      v-decorator="[
                      'username',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input user Username!'
                          },
                        ],
                        initialValue: userInfo.user.username,
                      },
                    ]"
                      placeholder="eg. Michael"/>
                </a-form-item>
              </a-col>
              <a-col :span="24">
                <a-form-item class="mb-10" label="Password" :colon="false">
                  <a-checkbox v-model="updatePassword">
                    Whether to reset the password?
                  </a-checkbox>
                </a-form-item>
              </a-col>
              <a-col v-if="updatePassword" :span="24" :sm="12">
                <a-form-item class="mb-10" label="Password" :colon="false">
                  <a-input-password
                      visibilityToggle
                      type="password"
                      v-decorator="[
                      'password',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input password!'
                          },
                          {
                            min: 6, message: 'at least 6 digits'
                          },
                        ],
                      },
                    ]"
                      placeholder="******"/>
                </a-form-item>
              </a-col>
              <a-col v-if="updatePassword" :span="24" :sm="12">
                <a-form-item class="mb-10" label="Repeat Password" :colon="false">
                  <a-input-password
                      visibilityToggle
                      type="password"
                      v-decorator="[
                      'repeatPassword',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input Repeat Password!'
                          },
                          {
                            min: 6, message: 'at least 6 digits'
                          },
                          {
                            validator: (rule, value, callback) => {
                              const form = this.form;
                              if (value && value !== form.getFieldValue('password')) {
                                callback('Two passwords that you enter is inconsistent!');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                      },
                    ]"
                      placeholder="******"/>
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="mt-20">Roles</h5>
            <a-row :gutter="[24]" type="flex">
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Roles" :colon="false">
                  <a-select
                      mode="multiple"
                      style="width: 100%"
                      placeholder="Please select"
                      optionFilterProp="title"
                      v-decorator="[
                      'roleIds',
                      {
                        rules: [
                          {
                            required: true, message: 'Please select Roles!'
                          },
                        ],
                        initialValue: userInfo.roles ? userInfo.roles.map((e)=>e.id): [],
                      },
                    ]"
                  >
                    <a-select-option v-for="role in roles" :key="role.id" :title="role.name + ' (#' + role.id + ')'">
                      {{ role.name }} (#{{ role.id }})
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="mt-20">User information</h5>
            <a-row type="flex" :gutter="[24]">
              <a-col :span="24" :sm="12">
                <a-form-item
                    class="mb-10"
                    label="Nickname"
                    :colon="false">
                  <a-input
                      placeholder="eg. Prior"
                      v-decorator="[
                      'nickname',
                      {
                        initialValue: userInfo.user.nickname,
                      },
                    ]"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item
                    class="mb-10"
                    label="Phone number"
                    :colon="false">
                  <a-input
                      placeholder="eg. "
                      v-decorator="[
                      'phone',
                      {
                        initialValue: userInfo.user.phone,
                      },
                    ]"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item
                    class="mb-10"
                    label="Email Address"
                    :colon="false">
                  <a-input
                      placeholder="eg. soft@dashboard.com"
                      v-decorator="[
                      'email',
                      {
                        initialValue: userInfo.user.email,
                      },
                    ]"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="24" class="text-right">
                <a-button type="link" @click="form.resetFields()" class="px-25 mr-15">Reset</a-button>
                <a-button type="primary" :loading="submitBtnLoading" html-type="submit"
                          class="px-25">Update
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-loading>

      </a-card>
    </div>

  </div>

</template>

<script>

export default {
  components: {},
  data() {
    return {
      form: this.$form.createForm(this, {name: 'updateUserForm'}),
      submitBtnLoading: false,
      pageLoadingState: 'loading',
      roles: [],
      userInfo: {
        user:{},
        roles: [],
      },
      updatePassword: false,
    }
  },
  async created() {
    this.pageLoadingState = 'loading';

    // console.log(this.form);
    // console.log(this.form.getFieldValue(''));

    let result = await this.$axios.post({
      url: '/ctcUserRole/query/listAll',
    });

    if (!result.success) {
      this.pageLoadingState = 'failed';
      return;
    }

    this.roles = result.body;

    await this.loadUserInfo();


  },
  methods: {
    async loadUserInfo(){

      this.updatePassword = false;
      let result2 = await this.$axios.post({
        url: '/ctcUser/query/loadById',
        data: {
          id: this.$route.params.id,
        }
      });

      if (result2.success) {
        this.userInfo = result2.body;
        this.pageLoadingState = 'success';
      } else {
        this.pageLoadingState = 'failed';
      }
    },
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();
      const that = this;


      const values = await this.$fvs.validateFieldsAndScroll(this.form);
      if (!values) {
        return;
      }

      that.submitBtnLoading = true;
      let result = await that.$axios.post({
        url: '/ctcUser/update/updateUser',
        data: {
          ...values,
          id: this.$route.params.id,
          updatePassword: this.updatePassword,
        },
      });

      if (result.success) {
        that.$message.success("update successful");

        this.pageLoadingState = 'loading';
        await this.loadUserInfo();
      }

      that.submitBtnLoading = false;

    },
    moveStep(distance) {
      this.step += distance;
    }
  },
}

</script>

<style lang="scss">
</style>
