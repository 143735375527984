var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-24 mx-auto",staticStyle:{"max-width":"800px"}},[_c('a-card',{staticClass:"header-solid",attrs:{"bordered":false}},[_c('a-loading',{attrs:{"state":_vm.pageLoadingState}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('h5',{staticClass:"mt-5"},[_vm._v("Site information")]),_c('a-row',{attrs:{"type":"flex","gutter":[24]}},[_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"required":true,"label":"Site #","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'clinicalPlaceNo',
                    {
                      rules: [
                        {
                          required: true, message: 'Please input Site #!'
                        } ],
                      initialValue: _vm.updateOriginal.ctcClinicalPlace.clinicalPlaceNo,
                    } ]),expression:"[\n                    'clinicalPlaceNo',\n                    {\n                      rules: [\n                        {\n                          required: true, message: 'Please input Site #!'\n                        },\n                      ],\n                      initialValue: updateOriginal.ctcClinicalPlace.clinicalPlaceNo,\n                    },\n                  ]"}],attrs:{"placeholder":"eg. Michael"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"required":true,"label":"Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'clinicalPlaceName',
                    {
                      rules: [
                        {
                          required: true, message: 'Please input Site Name!'
                        } ],
                      initialValue: _vm.updateOriginal.ctcClinicalPlace.clinicalPlaceName,
                    } ]),expression:"[\n                    'clinicalPlaceName',\n                    {\n                      rules: [\n                        {\n                          required: true, message: 'Please input Site Name!'\n                        },\n                      ],\n                      initialValue: updateOriginal.ctcClinicalPlace.clinicalPlaceName,\n                    },\n                  ]"}],attrs:{"placeholder":"eg. Michael"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Address","colon":false}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'clinicalPlaceAddress',
                    {
                      initialValue: _vm.updateOriginal.ctcClinicalPlace.clinicalPlaceAddress,
                    } ]),expression:"[\n                    'clinicalPlaceAddress',\n                    {\n                      initialValue: updateOriginal.ctcClinicalPlace.clinicalPlaceAddress,\n                    },\n                  ]"}],attrs:{"placeholder":"Please input Address","auto-size":{ minRows: 3, maxRows: 6 }}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Introduce","colon":false}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'clinicalPlaceIntroduce',
                    {
                      initialValue: _vm.updateOriginal.ctcClinicalPlace.clinicalPlaceIntroduce,
                    } ]),expression:"[\n                    'clinicalPlaceIntroduce',\n                    {\n                      initialValue: updateOriginal.ctcClinicalPlace.clinicalPlaceIntroduce,\n                    },\n                  ]"}],attrs:{"placeholder":"Please input Introduce","auto-size":{ minRows: 3, maxRows: 6 }}})],1)],1)],1),_c('h5',{staticClass:"mt-20"},[_vm._v("Staff")]),_c('a-row',{attrs:{"type":"flex","gutter":[24]}},[_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Staff","optionFilterProp":"title","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'clinicalPlaceUserIds',
                    {
                      initialValue: _vm.updateOriginal.clinicalPlaceUserIds,
                    } ]),expression:"[\n                    'clinicalPlaceUserIds',\n                    {\n                      initialValue: updateOriginal.clinicalPlaceUserIds,\n                    },\n                  ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"multiple","optionFilterProp":"title","placeholder":"Please select"}},_vm._l((_vm.cp_ep_qcs),function(item){return _c('a-select-option',{key:item.id,attrs:{"title":item.username + ' (#'+item.userNo+') - ['+item.roleNameAndIdsStr+']'}},[_vm._v(" "+_vm._s(item.username)+" (#"+_vm._s(item.userNo)+") - ["+_vm._s(item.roleNameAndIdsStr)+"] ")])}),1)],1)],1)],1),_c('a-row',{staticClass:"mt-15",attrs:{"type":"flex","gutter":[24]}},[_c('a-col',{staticClass:"text-right",attrs:{"span":24}},[_c('a-button',{staticClass:"px-25 mr-15",attrs:{"type":"link"},on:{"click":function($event){return _vm.form.resetFields()}}},[_vm._v("Reset")]),_c('a-button',{staticClass:"px-25",attrs:{"type":"primary","loading":_vm.submitBtnLoading,"html-type":"submit"}},[_vm._v("Update ")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }