var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto mt-50",staticStyle:{"max-width":"1024px"}},[_c('a-loading',{attrs:{"state":_vm.pageLoadingState}},[_c('div',{staticClass:"mb-50"},[_c('a-card',{staticClass:"header-solid",attrs:{"bordered":false}},[_c('a-form',{attrs:{"form":_vm.step1_form},on:{"submit":_vm.handleSubmit}},[_c('div',[_c('h5',{staticClass:"mt-5"},[_vm._v("Trial")]),_c('a-row',{attrs:{"gutter":[24],"type":"flex"}},[_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Trial","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'projectId',
                        {
                          rules: [
                            {
                              required: true, message: 'Please select Project!'
                            } ],
                          initialValue: _vm.updateOriginal.ctcProjectProduct.projectId,
                        } ]),expression:"[\n                        'projectId',\n                        {\n                          rules: [\n                            {\n                              required: true, message: 'Please select Project!'\n                            },\n                          ],\n                          initialValue: updateOriginal.ctcProjectProduct.projectId,\n                        },\n                  ]"}],staticStyle:{"width":"100%"},attrs:{"show-search":"","optionFilterProp":"title","placeholder":"Please select","disabled":true}},_vm._l((_vm.allProjects),function(item){return _c('a-select-option',{key:item.id,attrs:{"title":item.projectName + ' (#'+item.projectNo+')'}},[_vm._v(" "+_vm._s(item.projectName)+" (#"+_vm._s(item.projectNo)+") ")])}),1)],1)],1)],1),_c('h5',{staticClass:"mt-5"},[_vm._v("Device")]),_c('a-row',{attrs:{"gutter":[24],"type":"flex"}},[_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Device #","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'productNo',
                        {
                          rules: [
                            {
                              required: true, message: 'Please input Device #!'
                            } ],
                          initialValue: _vm.updateOriginal.ctcProjectProduct.productNo,
                        } ]),expression:"[\n                        'productNo',\n                        {\n                          rules: [\n                            {\n                              required: true, message: 'Please input Device #!'\n                            },\n                          ],\n                          initialValue: updateOriginal.ctcProjectProduct.productNo,\n                        },\n                      ]"}],attrs:{"placeholder":"eg. Street 111"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Device Name","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'productName',
                        {
                          rules: [
                            {
                              required: true, message: 'Please input Device Name!'
                            } ],
                          initialValue: _vm.updateOriginal.ctcProjectProduct.productName,
                        } ]),expression:"[\n                        'productName',\n                        {\n                          rules: [\n                            {\n                              required: true, message: 'Please input Device Name!'\n                            },\n                          ],\n                          initialValue: updateOriginal.ctcProjectProduct.productName,\n                        },\n                      ]"}],attrs:{"placeholder":"eg. Street 111"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"FDA Product Code","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'fdaProductCode',
                        {
                          initialValue: _vm.updateOriginal.ctcProjectProduct.fdaProductCode,
                        } ]),expression:"[\n                        'fdaProductCode',\n                        {\n                          initialValue: updateOriginal.ctcProjectProduct.fdaProductCode,\n                        },\n                      ]"}],attrs:{"placeholder":"eg. Street 111"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Panel","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'panel',
                        {
                          initialValue: _vm.updateOriginal.ctcProjectProduct.panel,
                        } ]),expression:"[\n                        'panel',\n                        {\n                          initialValue: updateOriginal.ctcProjectProduct.panel,\n                        },\n                      ]"}],attrs:{"placeholder":"eg. Street 111"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Manufacturer","colon":false}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'productManufacturer',
                        {
                          initialValue: _vm.updateOriginal.ctcProjectProduct.productManufacturer,
                        } ]),expression:"[\n                    'productManufacturer',\n                        {\n                          initialValue: updateOriginal.ctcProjectProduct.productManufacturer,\n                        },\n                  ]"}],attrs:{"placeholder":"Manufacturer info","auto-size":{ minRows: 3, maxRows: 6 }}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Introduce","colon":false}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'productIntroduce',
                        {
                          initialValue: _vm.updateOriginal.ctcProjectProduct.productIntroduce,
                        } ]),expression:"[\n                    'productIntroduce',\n                        {\n                          initialValue: updateOriginal.ctcProjectProduct.productIntroduce,\n                        },\n                  ]"}],attrs:{"placeholder":"Introduce","auto-size":{ minRows: 3, maxRows: 6 }}})],1)],1)],1)],1),_c('a-row',{staticClass:"mt-15",attrs:{"type":"flex","gutter":[24]}},[_c('a-col',{staticClass:"text-right",attrs:{"span":24}},[_c('a-button',{staticClass:"px-25",attrs:{"type":"primary","loading":_vm.submitBtnLoading,"html-type":"submit"}},[_vm._v("Update")])],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }