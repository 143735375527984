<!--
	This is the basic sign up page, it uses the default layout in:
	"./layouts/Default.vue" .
 -->

<template>
  <div>

    <!-- Sign Up Image And Headings -->
    <div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
      <div class="content ">

        <img src="/images/logo-white-border.png" width="150" style="max-width: 100%;"/>
        <div class="mt-10 font-size-18">{{ appName }}</div>
      </div>
    </div>
    <!-- / Sign Up Image And Headings -->

    <!-- Sign Up Card -->
    <a-card :bordered="false" class="card-signup header-solid" :bodyStyle="{paddingTop: 0}">
      <div class="py-40 text-center">
        <h1 class="mb-5">Sign In</h1>
        <p class="text-lg">Please use the username and account provided by hmc to sign in</p>
      </div>

      <!-- Sign Up Form -->
      <a-form
          id="components-form-demo-normal-login"
          :form="form"
          class="login-form mx-25"
          @submit="handleSubmit"
      >
        <a-form-item class="mb-15">
          <a-input
              size="large"
              v-decorator="[
              'username',
              { rules: [{ required: true, message: 'Please input your Username!' }] },
              ]"
              placeholder="Username"
          >
            <a-icon slot="prefix" type="user"/>
          </a-input>
        </a-form-item>
        <a-form-item class="mb-5">
          <a-input-password
              visibilityToggle
              size="large"
              v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your Password!' }] },
						]"
              type="password"
              placeholder="Password"
          >
            <a-icon slot="prefix" type="lock"/>
          </a-input-password>
        </a-form-item>
        <a-form-item class="mb-10">
          <a-checkbox
              v-decorator="[
						'agree',
						{
							valuePropName: 'checked',
							initialValue: true,
						},
						]"
          >
            I agree the <a href="#" class="font-bold text-dark">Terms and Conditions</a>
          </a-checkbox>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" :loading="submitBtnLoading" size="large" block html-type="submit"
                    class="login-form-button">
            SIGN IN
          </a-button>
        </a-form-item>
      </a-form>
      <!-- / Sign Up Form -->

      <!--			<p class="font-semibold text-muted text-center">Already have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign In</router-link></p>-->
    </a-card>
    <!-- / Sign Up Card -->

  </div>
</template>

<script>
const appConfig = require("../../../../public/config");

export default ({
  data() {
    return {
      // Sign up form object.
      form: this.$form.createForm(this, {name: 'signup_basic'}),
      appName: appConfig.app.name,
      submitBtnLoading: false,
    }
  },
  created() {
    // console.log(this.$route)
    if (this.$route.query.showErr) {

      this.$message.warn('The Sign-in status is invalid, please Sign in again');
      // this.$notification.open({
      //   message: 'Tips',
      //   description:
      //       'The Sign-in status is invalid, please Sign in again',
      //   onClick: () => {
      //     // console.log('Notification Clicked!');
      //   },
      // });
    }
  },
  methods: {
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();

      const that = this;


      const values = await this.$fvs.validateFieldsAndScroll(this.form);
      if (!values) {
        return;
      }

      this.submitBtnLoading = true;
      let result = await that.$axios.post({
        url: '/ctcUser/operate/login',
        data: values,
      });

      if (result.success) {
        this.$message.success("login successful");
        localStorage.setItem("HMC_ROLES", result.body.roles);
        localStorage.setItem("HMC_USER", JSON.stringify(result.body.user));
        this.$router.replace(this.$route.query.from ? this.$route.query.from : "/");
      } else {
        this.submitBtnLoading = false;
      }


      // e.preventDefault();
      // this.form.validateFieldsAndScroll((err, values) => {
      //   if (!err) {
      //     console.log('Received values of form: ', values);
      //   }
      //   localStorage.setItem("HMC_ROLES", "ADMIN,TEST");
      //
      //   window.location.replace("/");
      // });
    },
  },
})

</script>

<style lang="scss">
</style>
