var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-24 mx-auto",staticStyle:{"max-width":"800px"}},[_c('a-card',{staticClass:"header-solid",attrs:{"bordered":false}},[_c('a-loading',{attrs:{"state":_vm.pageLoadingState}},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('h5',{staticClass:"mt-5"},[_vm._v("Login information")]),_c('a-row',{attrs:{"gutter":[24],"type":"flex"}},[_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"required":true,"label":"User #","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'userNo',
                    {
                      rules: [
                        {
                          required: true, message: 'Please input user no!'
                        } ],
                      initialValue: _vm.userInfo.user.userNo,
                    } ]),expression:"[\n                    'userNo',\n                    {\n                      rules: [\n                        {\n                          required: true, message: 'Please input user no!'\n                        },\n                      ],\n                      initialValue: userInfo.user.userNo,\n                    },\n                  ]"}],attrs:{"placeholder":"eg. Michael"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"required":true,"label":"Username","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'username',
                    {
                      rules: [
                        {
                          required: true, message: 'Please input user Username!'
                        } ],
                      initialValue: _vm.userInfo.user.username,
                    } ]),expression:"[\n                    'username',\n                    {\n                      rules: [\n                        {\n                          required: true, message: 'Please input user Username!'\n                        },\n                      ],\n                      initialValue: userInfo.user.username,\n                    },\n                  ]"}],attrs:{"placeholder":"eg. Michael"}})],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Password","colon":false}},[_c('a-checkbox',{model:{value:(_vm.updatePassword),callback:function ($$v) {_vm.updatePassword=$$v},expression:"updatePassword"}},[_vm._v(" Whether to reset the password? ")])],1)],1),(_vm.updatePassword)?_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Password","colon":false}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'password',
                    {
                      rules: [
                        {
                          required: true, message: 'Please input password!'
                        },
                        {
                          min: 6, message: 'at least 6 digits'
                        } ],
                    } ]),expression:"[\n                    'password',\n                    {\n                      rules: [\n                        {\n                          required: true, message: 'Please input password!'\n                        },\n                        {\n                          min: 6, message: 'at least 6 digits'\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"visibilityToggle":"","type":"password","placeholder":"******"}})],1)],1):_vm._e(),(_vm.updatePassword)?_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Repeat Password","colon":false}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'repeatPassword',
                    {
                      rules: [
                        {
                          required: true, message: 'Please input Repeat Password!'
                        },
                        {
                          min: 6, message: 'at least 6 digits'
                        },
                        {
                          validator: function (rule, value, callback) {
                            var form = this$1.form;
                            if (value && value !== form.getFieldValue('password')) {
                              callback('Two passwords that you enter is inconsistent!');
                            } else {
                              callback();
                            }
                          },
                        } ],
                    } ]),expression:"[\n                    'repeatPassword',\n                    {\n                      rules: [\n                        {\n                          required: true, message: 'Please input Repeat Password!'\n                        },\n                        {\n                          min: 6, message: 'at least 6 digits'\n                        },\n                        {\n                          validator: (rule, value, callback) => {\n                            const form = this.form;\n                            if (value && value !== form.getFieldValue('password')) {\n                              callback('Two passwords that you enter is inconsistent!');\n                            } else {\n                              callback();\n                            }\n                          },\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"visibilityToggle":"","type":"password","placeholder":"******"}})],1)],1):_vm._e()],1),_c('h5',{staticClass:"mt-20"},[_vm._v("Roles")]),_c('a-row',{attrs:{"gutter":[24],"type":"flex"}},[_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Roles","colon":false}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'roleIds',
                    {
                      rules: [
                        {
                          required: true, message: 'Please select Roles!'
                        } ],
                      initialValue: _vm.userInfo.roles ? _vm.userInfo.roles.map(function (e){ return e.id; }): [],
                    } ]),expression:"[\n                    'roleIds',\n                    {\n                      rules: [\n                        {\n                          required: true, message: 'Please select Roles!'\n                        },\n                      ],\n                      initialValue: userInfo.roles ? userInfo.roles.map((e)=>e.id): [],\n                    },\n                  ]"}],staticStyle:{"width":"100%"},attrs:{"mode":"multiple","placeholder":"Please select","optionFilterProp":"title"}},_vm._l((_vm.roles),function(role){return _c('a-select-option',{key:role.id,attrs:{"title":role.name + ' (#' + role.id + ')'}},[_vm._v(" "+_vm._s(role.name)+" (#"+_vm._s(role.id)+") ")])}),1)],1)],1)],1),_c('h5',{staticClass:"mt-20"},[_vm._v("User information")]),_c('a-row',{attrs:{"type":"flex","gutter":[24]}},[_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Nickname","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'nickname',
                    {
                      initialValue: _vm.userInfo.user.nickname,
                    } ]),expression:"[\n                    'nickname',\n                    {\n                      initialValue: userInfo.user.nickname,\n                    },\n                  ]"}],attrs:{"placeholder":"eg. Prior"}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Phone number","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'phone',
                    {
                      initialValue: _vm.userInfo.user.phone,
                    } ]),expression:"[\n                    'phone',\n                    {\n                      initialValue: userInfo.user.phone,\n                    },\n                  ]"}],attrs:{"placeholder":"eg. "}})],1)],1),_c('a-col',{attrs:{"span":24,"sm":12}},[_c('a-form-item',{staticClass:"mb-10",attrs:{"label":"Email Address","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'email',
                    {
                      initialValue: _vm.userInfo.user.email,
                    } ]),expression:"[\n                    'email',\n                    {\n                      initialValue: userInfo.user.email,\n                    },\n                  ]"}],attrs:{"placeholder":"eg. soft@dashboard.com"}})],1)],1)],1),_c('a-row',{staticClass:"mt-15",attrs:{"type":"flex","gutter":[24]}},[_c('a-col',{staticClass:"text-right",attrs:{"span":24}},[_c('a-button',{staticClass:"px-25 mr-15",attrs:{"type":"link"},on:{"click":function($event){return _vm.form.resetFields()}}},[_vm._v("Reset")]),_c('a-button',{staticClass:"px-25",attrs:{"type":"primary","loading":_vm.submitBtnLoading,"html-type":"submit"}},[_vm._v("Update ")])],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }