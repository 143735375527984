<template>
  <a-form-item class="mb-10" :label="title" :colon="false">
    <a-input-number
        v-if="type === 'int'"
        class="ant-input"
        :placeholder="`Please input ${title}`"
        style="width: 100%"
        :precision="0"
        v-decorator="[
            keyName,
            {
              rules: [
                {
                  required: required,
                  message: `Please input ${title}.`,
                },
              ],
            },
          ]"/>
    <a-input-number
        v-else-if="type === 'float'"
        class="ant-input"
        :placeholder="`Please input ${title}`"
        style="width: 100%"
        v-decorator="[
            keyName,
            {
              rules: [
                {
                  required: required,
                  message: `Please input ${title}.`,
                },
              ],
            },
          ]"/>

    <a-date-picker
        v-else-if="type === 'date'"
        style="width: 100%"
        valueFormat="YYYY-MM-DD"
        :placeholder="`Please select ${title}`"
        v-decorator="[
            keyName,
            {
              rules: [
                {
                  required: required,
                  message: `Please select ${title}.`,
                },
              ],
            },
          ]"/>

    <a-time-picker
        v-else-if="type === 'time'"
        style="width: 100%"
        valueFormat="HH:mm:ss"
        :placeholder="`Please select ${title}`"
        :default-open-value="moment()"
        :inputReadOnly="true"
        v-decorator="[
            keyName,
            {
              rules: [
                {
                  required: required,
                  message: `Please select ${title}.`,
                },
              ],
            },
          ]"/>


    <a-date-picker
        v-else-if="type === 'dateTime'"
        style="width: 100%"
        show-time
        valueFormat="YYYY-MM-DD HH:mm:ss"
        :placeholder="`Please select ${title}`"
        v-decorator="[
            keyName,
            {
              rules: [
                {
                  required: required,
                  message: `Please select ${title}.`,
                },
              ],
            },
          ]"/>

    <a-input
        v-else-if="type === 'string'"
        :placeholder="`Please input ${title}`"
        v-decorator="[
            keyName,
            {
              rules: [
                {
                  required: required,
                  message: `Please input ${title}.`,
                },
              ],
            },
          ]"/>
    <a-radio-group
        v-else-if="type === 'boolean'"
        :placeholder="`Please select ${title}`"
        v-decorator="[
            keyName,
            {
              rules: [
                {
                  required: required,
                  message: `Please select ${title}.`,
                },
              ],
            },
          ]">
      <a-radio :value="true">
        Yes
      </a-radio>
      <a-radio :value="false">
        No
      </a-radio>
    </a-radio-group>
    <a-radio-group
        v-else-if="type === 'radio'"
        :placeholder="`Please select ${title}`"
        v-decorator="[
            keyName,
            {
              rules: [
                {
                  required: required,
                  message: `Please select ${title}.`,
                },
              ],
            },
          ]">
      <a-radio :value="item" :key="`${keyName}_${index}`" v-for="(item, index) in values">
        {{ item }}
      </a-radio>
    </a-radio-group>
    <a-checkbox-group
        v-else-if="type === 'checkBox'"
        :placeholder="`Please select ${title}`"
        v-decorator="[
            keyName,
            {
              rules: [
                {
                  required: required,
                  message: `Please select ${title}.`,
                },
              ],
            },
          ]">
      <a-checkbox :value="item" :key="`${keyName}_${index}`" v-for="(item, index) in values">
        {{ item }}
      </a-checkbox>
    </a-checkbox-group>
  </a-form-item>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    keyName: {
      type: String,
      default: "-", // loading, empty, failed
    },
    title: {
      type: String,
      default: "-", // loading, empty, failed
    },
    type: {
      type: String,
      default: "String", // loading, empty, failed
    },
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Array,
      default: ()=>[],
    }
  },
  data() {
    return {};
  },
  methods: {
    moment,
  },
}
</script>

<style scoped>

</style>