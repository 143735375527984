<!--
	This is the new user page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>

    <div class="mb-24 mx-auto" style="max-width: 800px">
      <a-card :bordered="false" class="header-solid">
        <a-loading :state="pageLoadingState">
          <a-form
              :form="form"
              @submit="handleSubmit"
          >
            <h5 class="mt-5">Login information</h5>
            <a-row type="flex" :gutter="[24]">
              <a-col :span="24" :sm="12">
                <a-form-item :required="true" class="mb-10" label="User #" :colon="false">
                  <a-input

                      v-decorator="[
                      'userNo',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input User #!'
                          },
                        ],
                      },
                    ]"
                      placeholder="eg. Michael"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item :required="true" class="mb-10" label="Username" :colon="false">
                  <a-input

                      v-decorator="[
                      'username',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input user Username!'
                          },
                        ],
                      },
                    ]"
                      placeholder="eg. Michael"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Password" :colon="false">
                  <a-input-password
                      visibilityToggle
                      type="password"
                      v-decorator="[
                      'password',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input password!'
                          },
                          {
                            min: 6, message: 'at least 6 digits'
                          },
                        ],
                      },
                    ]"
                      placeholder="******"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Repeat Password" :colon="false">
                  <a-input-password
                      visibilityToggle
                      type="password"
                      v-decorator="[
                      'repeatPassword',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input Repeat Password!'
                          },
                          {
                            min: 6, message: 'at least 6 digits'
                          },
                          {
                            validator: (rule, value, callback) => {
                              const form = this.form;
                              if (value && value !== form.getFieldValue('password')) {
                                callback('Two passwords that you enter is inconsistent!');
                              } else {
                                callback();
                              }
                            },
                          },
                        ],
                      },
                    ]"
                      placeholder="******"/>
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="mt-20">Roles</h5>
            <a-row type="flex" :gutter="[24]">
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Roles" :colon="false">
                  <a-select
                      mode="multiple"
                      style="width: 100%"
                      placeholder="Please select"
                      optionFilterProp="title"
                      v-decorator="[
                      'roleIds',
                      {
                        rules: [
                          {
                            required: true, message: 'Please select Roles!'
                          },
                        ],
                      },
                    ]"
                  >
                    <a-select-option v-for="role in roles" :key="role.id" :title="role.name + ' (#' + role.id + ')'">
                      {{ role.name }} (#{{ role.id }})
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="mt-20">User information</h5>
            <a-row type="flex" :gutter="[24]">
              <a-col :span="24" :sm="12">
                <a-form-item
                    class="mb-10"
                    label="Nickname"
                    :colon="false">
                  <a-input
                      placeholder="eg. Prior"
                      v-decorator="[
                      'nickname',
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item
                    class="mb-10"
                    label="Phone number"
                    :colon="false">
                  <a-input
                      placeholder="eg. "
                      v-decorator="[
                      'phone',
                    ]"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item
                    class="mb-10"
                    label="Email Address"
                    :colon="false">
                  <a-input
                      placeholder="eg. soft@dashboard.com"
                      v-decorator="[
                      'email',
                    ]"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="24" class="text-right">
                <a-button type="link" @click="form.resetFields()" class="px-25 mr-15">Reset</a-button>
                <a-button type="primary" :loading="submitBtnLoading" html-type="submit"
                          class="px-25">Add
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-loading>

      </a-card>
    </div>

  </div>

</template>

<script>

export default {
  components: {},
  data() {
    return {
      form: this.$form.createForm(this, {name: 'newUserForm'}),
      submitBtnLoading: false,
      pageLoadingState: 'loading',
      roles: [],
    }
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    // this.form = this.$form.createForm(this, {name: 'user'});
  },
  async created() {
    this.pageLoadingState = 'loading';
    let result = await this.$axios.post({
      url: '/ctcUserRole/query/listAll',
    });

    if (result.success) {
      this.roles = result.body;
      this.pageLoadingState = 'success';
    } else {
      this.pageLoadingState = 'failed';
    }
    const that = this;
    // window.setTimeout(()=>{
    //   console.log('aaa')
    //   that.form.resetFields();
    // }, 5000);
    // console.log(this.form)

  },
  methods: {
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();
      const that = this;


      const values = await this.$fvs.validateFieldsAndScroll(this.form);
      if (!values) {
        return;
      }

      that.submitBtnLoading = true;
      let result = await that.$axios.post({
        url: '/ctcUser/add/addUser',
        data: values,
      });

      if (result.success) {
        that.$message.success("add successful");

        that.$confirm({
          title: 'Do you want to continue adding?',
          okText: 'Continue',
          cancelText: 'View user list',
          onOk() {
            that.form.resetFields();
            that.submitBtnLoading = false;
          },
          onCancel() {
            that.$router.replace("/settings/user-setting/user-setting-list");
          },
        });


      } else {
        that.submitBtnLoading = false;
      }
    },
    moveStep(distance) {
      this.step += distance;
    }
  },
}

</script>

<style lang="scss">
</style>
