<!--
	This is the new user page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div class="mx-auto mt-50" style="max-width: 1024px;">

    <a-loading :state="pageLoadingState">
      <div class="mb-50">
        <!-- Step 1 -->
        <a-card v-show="step === 0" :bordered="false" class="header-solid">
          <h5 class="mt-5">BASIC INFORMATION</h5>
          <a-form
              :form="step0_form"
              @submit="handleSubmit"
          >
            <a-row :gutter="[24]" type="flex">
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial #" :colon="false">
                  <a-input
                      placeholder="eg. 01"
                      v-decorator="[
                      'projectNo',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input Trial #!'
                          },
                        ],
                        initialValue: updateOriginal.project.projectNo,
                      },
                    ]"/>
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial Name" :colon="false">
                  <a-input
                      placeholder="eg. Michael"
                      v-decorator="[
                      'projectName',
                      {
                        rules: [
                          {
                            required: true, message: 'Please input Name!'
                          },
                        ],
                        initialValue: updateOriginal.project.projectName,
                      },
                    ]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial introduce" :colon="false">
                  <a-textarea
                      placeholder="Autosize height with"
                      :auto-size="{ minRows: 3, maxRows: 6 }"
                      v-decorator="[
                      'projectIntroduce',
                      {
                        initialValue: updateOriginal.project.projectIntroduce,
                      },
                    ]"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="mt-5">CRO Staff</h5>
            <a-row :gutter="[24]" type="flex">
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="CRO Staffs" :colon="false">
                  <a-select
                      mode="multiple"
                      optionFilterProp="title"
                      style="width: 100%"
                      placeholder="Please select"
                      v-decorator="[
                      'userIds',
                      {
                        initialValue: updateOriginal.userIds,
                      },
                    ]"
                  >
                    <a-select-option v-for="item in croEpAndQcs" :key="item.id"
                                     :title="item.username + ' (#'+item.userNo+') - ['+item.roleNameAndIdsStr+']'">
                      {{ item.username }} (#{{ item.userNo }}) - [{{ item.roleNameAndIdsStr }}]
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <h5 class="mt-5">Files</h5>
            <a-row :gutter="[24]" type="flex">
              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial Files" :colon="false">
                  <div class="h-5"></div>
                  <a-upload-dragger
                      accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                      name="file"
                      :multiple="true"
                      :withCredentials="true"
                      :default-file-list="defaultProjectFileList"
                      action="/api/ctcProject/upload/file"
                      @change="(info)=>handleUploadChange(info, projectFileList, defaultProjectFileList)"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox"/>
                    </p>
                    <p class="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p class="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p>
                  </a-upload-dragger>
                </a-form-item>
              </a-col>

              <a-col :span="24" :sm="12">
                <a-form-item class="mb-10" label="Trial Files" :colon="false">
                  <div class="h-5"></div>
                  <a-upload-dragger
                      accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                      name="file"
                      :multiple="true"
                      :withCredentials="true"
                      action="/api/ctcProject/upload/file"
                      :default-file-list="defaultProjectComplianceFileList"
                      @change="(info)=>handleUploadChange(info, projectComplianceFileList, defaultProjectComplianceFileList)"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox"/>
                    </p>
                    <p class="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p class="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p>
                  </a-upload-dragger>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="[24]" class="mt-15">
              <a-col :span="24" class="text-right">
                <a-button type="primary" :loading="submitBtnLoading" html-type="submit" class="px-25">Update</a-button>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </div>
    </a-loading>

  </div>

</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      step: 0,
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true,
      step0_form: this.$form.createForm(this, {name: 'step0_form'}),
      pageLoadingState: 'loading',
      croEpAndQcs: [],
      projectFileList: [],
      projectComplianceFileList: [],
      submitBtnLoading: false,
      products: [],
      updateOriginal: {
        project: {},
      },
      defaultProjectFileList: [],
      defaultProjectComplianceFileList: [],
    }
  },
  async created() {
    console.log(this.$router)

    this.pageLoadingState = 'loading';

    let result = await this.$axios.post({
      url: '/ctcUser/query/listCroEpAndQc',
    });

    if (!result.success) {
      this.pageLoadingState = 'failed';
      return;
    }
    this.croEpAndQcs = result.body;

    await this.loadData();
  },
  methods: {
    async loadData() {

      let result3 = await this.$axios.post({
        url: '/ctcProject/query/loadById',
        data: {
          id: this.$route.params.id,
        },
      });

      if (result3.success) {
        this.updateOriginal = result3.body;

        if(result3.body.project){

          if (result3.body.project.projectFiles) {
            this.defaultProjectFileList = JSON.parse(result3.body.project.projectFiles);
          }
          if (result3.body.project.projectComplianceFiles) {
            this.defaultProjectComplianceFileList = JSON.parse(result3.body.project.projectComplianceFiles);
          }
        }

        this.pageLoadingState = 'success';
      } else {
        this.pageLoadingState = 'failed';
      }
    },
    // Handles input validation after submission.
    async handleSubmit(e) {
      e.preventDefault();

      const values0 = await this.$fvs.validateFieldsAndScroll(this.step0_form);
      if (!values0) {
        this.$message.error("Project Info incomplete data.");
        return;
      }
      const _projectFileList = (this.projectFileList ? this.projectFileList : []).concat(this.defaultProjectFileList);
      const _projectComplianceFileList = (this.projectComplianceFileList ? this.projectComplianceFileList : []).concat(this.defaultProjectComplianceFileList);
      let params = {
        ...{
          ...values0,
          projectFiles: JSON.stringify(_projectFileList),
          projectComplianceFiles: JSON.stringify(_projectComplianceFileList),
        },
        id: this.$route.params.id,
      };

      this.submitBtnLoading = true;

      let result = await this.$axios.post({
        url: '/ctcProject/update/byId',
        data: params,
      });

      if (result.success) {
        const that = this;
        this.$message.success("Project update success.").then(()=>{
          that.submitBtnLoading = false;
          that.$router.push("/basic-manage/trial-manage/trial-list");
        });

      } else {
        this.submitBtnLoading = false;
      }

    },
    handleUploadChange(info, fileList, defaultFileList) {
      const {file} = info;
      console.log("info", info);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const {response} = file;
        const {body, code, msg, success} = response;

        if(code !== 'SUCCESS' || !success){
          this.$message.error(`${info.file.name} file upload failed. ${msg}`);
          return;
        }
        if (parseInt(code) === 100000 || code === '100000') {
          localStorage.removeItem("HMC_ROLES");
          localStorage.removeItem("HMC_USER");
          window.location.reload();
          return;
        }

        fileList.push({
          uid: file.uid,
          status: 'done',
          ...body,
        });

        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === "removed") {
        let foundIndex = fileList.findIndex((e) => e.uid === file.uid);

        if (foundIndex >= 0) {
          fileList.splice(foundIndex, 1);
          this.$message.success(`${info.file.name} file removed.`);
        } else {
          foundIndex = defaultFileList.findIndex((e) => e.uid === file.uid);

          if (foundIndex >= 0) {
            defaultFileList.splice(foundIndex, 1);
            this.$message.success(`${info.file.name} file removed.`);
          } else {
            this.$message.error(`${info.file.name} file not found, failed to remove.`);
          }
        }
      }
      console.log("fileList", fileList);
    },
  },
}

</script>
